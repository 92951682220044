<template>
    <div class="w-full">
        <div v-if="projects.length === 0 && !loading" class="flex flex-col items-center min-h-main">
            <div class="flex flex-col items-center mt-10">
                <h2 class="my-2 text-2xl text-center">{{ $t('projects.emptyTitle') }}</h2>
                <div class="my-5 text-center">
                    <app-button
                        variant="primary"
                        :label="$t('projects.newProject')"
                        @click="navigateToNewProject()"
                    ></app-button>
                </div>
            </div>
            <Support />
        </div>
        <div v-else class="flex flex-col items-center justify-center w-full">
            <div class="flex items-center justify-between gap-4 my-2 p-2 w-full">
                <div>
                    <app-button
                        variant="primary"
                        :label="$t('projects.newProject')"
                        @click="navigateToNewProject()"
                    ></app-button>
                </div>
                <div class="flex flex-grow justify-center">
                    <app-filter
                        ref="filter"
                        class="md:max-w-1/2"
                        v-model="filterAsString"
                        @input="onFilterInput"
                    ></app-filter>
                </div>
                <div class="text-xs flex items-center flex-col">
                    <a class="flex items-center gap-2 text-main underline pointer" @click="openHelpPopup" href="#">
                        <icon-lifebuoy width="24" height="24" class="inline" />
                        <span>{{ $t('menu.needHelp') }}</span>
                    </a>
                    <div class="hidden md:flex justify-start text-gray-500 w-full">
                        <app-upgrade-button :show-icon="true">
                            <span class="">v3.0.1.PATCH_VERSION</span>
                        </app-upgrade-button>
                    </div>
                </div>
            </div>
            <div class="p-2 container m-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                <div
                    class="shadow m-2 group relative"
                    :class="{ 'col-start-1': project.isFirstNotFavorite }"
                    v-for="project in filteredProjects"
                    :key="project.id"
                >
                    <template v-if="!isDeliveryContactOnly(project.me)">
                        <button
                            :title="`retirer l'opération ${project.name} des favoris`"
                            v-if="project.isFavorite"
                            class="absolute top-0 left-0 m-1 z-10"
                            @click="removeFromFavorite(project)"
                        >
                            <icon-star class="text-yellow-500"></icon-star>
                        </button>
                        <button
                            :title="`ajouter l'opération ${project.name} aux favoris`"
                            v-if="!project.isFavorite"
                            class="absolute top-0 left-0 m-1 z-10"
                            @click="addToFavorite(project)"
                        >
                            <icon-star-outline v-if="!project.isFavorite" class="text-yellow-500"></icon-star-outline>
                        </button>
                    </template>
                    <button
                        class="absolute top-0 right-0 m-1 z-10 group"
                        @click="syncProject(project.id)"
                        v-if="project.tryAllRemaining > 0"
                    >
                        <div title="Synchroniser l'opération" v-if="project.syncState.state === 'unknown'">
                            <icon-cloud-outline width="16" height="16" class="text-gray-300"></icon-cloud-outline>
                        </div>
                        <div
                            :title="'Dernière synchro le ' + humanizeDate(new Date(project.syncState.lastSuccess))"
                            v-if="
                                project.syncState.state === 'ok' &&
                                new Date(project.syncState.lastSuccess).getTime() - 8 * 3600 > new Date().getTime()
                            "
                        >
                            <icon-cloud-check width="16" height="16" class="text-yellow-500"></icon-cloud-check>
                        </div>
                        <div title="Synchro en cours" v-if="project.syncState.state === 'pending'">
                            <icon-cloud-refresh width="16" height="16" class="text-blue-500"></icon-cloud-refresh>
                        </div>
                        <div
                            title="Dernière synchro en erreur, cliquez pour relancer."
                            v-if="project.syncState.state === 'error'"
                        >
                            <icon-cloud-alert width="16" height="16" class="text-red-500"></icon-cloud-alert>
                        </div>
                        <div
                            :title="'Dernière synchro le ' + humanizeDate(new Date(project.syncState.lastSuccess))"
                            v-if="
                                project.syncState.state === 'ok' &&
                                new Date(project.syncState.lastSuccess).getTime() - 8 * 3600 <= new Date().getTime()
                            "
                        >
                            <icon-cloud-check width="16" height="16" class="text-green-600"></icon-cloud-check>
                        </div>
                    </button>
                    <div class="relative" aria-hidden="true">
                        <img
                            v-if="project.tryAllRemaining < 0"
                            class="h-40 w-full object-cover"
                            :src="project.imageUrl || '/defaultImage.svg'"
                            :alt="project.name"
                        />
                        <router-link
                            v-else
                            :to="
                                isDeliveryContactOnly(project.me)
                                    ? { name: 'requests', params: { projectId: project.id } }
                                    : isCompany(project.me)
                                    ? {
                                          name: 'followByBundle',
                                          params: { projectId: project.id, bundleId: project.me.bundleIds[0] },
                                      }
                                    : { name: 'myDashboard', params: { projectId: project.id } }
                            "
                        >
                            <img
                                class="h-40 w-full object-cover cursor-pointer"
                                :src="project.imageUrl || '/defaultImage.svg'"
                                :alt="project.name"
                            />
                        </router-link>
                    </div>
                    <div class="pb-2">
                        <div
                            class="bg-red-600 text-white text-xs w-full p-1 text-center"
                            v-if="project.tryAllRemaining !== null"
                        >
                            <span v-if="project.tryAllRemaining > 0">
                                {{ $t('projects.expireIn') }} {{ project.tryAllRemaining }} {{ $t('projects.days') }}
                            </span>
                            <span v-if="project.tryAllRemaining < 0">
                                <a
                                    class="hover:underline"
                                    :href="'https://app.tex.moe/project/' + project.id + '/information'"
                                >
                                    {{ $t('projects.expiredSince') }} {{ -project.tryAllRemaining }}
                                    {{ $t('projects.days') }}
                                </a>
                            </span>
                            <span v-if="project.tryAllRemaining === 0">
                                <a
                                    class="hover:underline"
                                    :href="'https://app.tex.moe/project/' + project.id + '/join'"
                                >
                                    {{ $t('projects.expiredToday') }}
                                </a>
                            </span>
                        </div>
                        <div class="px-2 text-xs text-gray-700 italic flex">
                            <span v-if="project.code">#{{ project.code }}</span>
                            <span v-if="project.city" class="mx-1">{{ project.city }}</span>
                            <span class="flex-grow"></span>
                            <span>{{ project.phase ? $t('commons.phases.' + project.phase) : '' }}</span>
                        </div>
                        <h2 class="px-2 font-bold text-sm text-center">
                            <span v-if="project.tryAllRemaining < 0">{{ project.name }}</span>
                            <router-link
                                v-else
                                :to="
                                    isDeliveryContactOnly(project.me)
                                        ? { name: 'requests', params: { projectId: project.id } }
                                        : { name: 'myDashboard', params: { projectId: project.id } }
                                "
                            >
                                {{ project.name }}
                            </router-link>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <app-popup ref="debugPopup" class="m-4 max-w-3/4">
            <app-separator class="bg-red-300" label="Debug"></app-separator>
            <div class="flex p-4 flex-col gap-4">
                <div><app-button label="Télécharger une sauvegarde" @click="exportBackup"></app-button></div>
                <div><app-button variant="danger" label="réinitialiser l'application" @click="reset"></app-button></div>
            </div>
        </app-popup>
        <app-popup ref="helpPopup" class="m-4 max-w-3/4">
            <app-separator :label="$t('projects.help')"></app-separator>
            <Support />
        </app-popup>
    </div>
</template>

<script>
import AppMail from '../../components/appMail/AppMail';
import AppButton from '../../components/appButton/AppButton';
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppFilter from '@/components/appFilter/AppFilter';
import { getProjects, updateProject } from '@/features/projects/projects.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import database from '@/rxdb/database';
import AppPopup from '@/components/app-popup/AppPopup';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import IconStar from '@/icons/IconStar';
import IconStarOutline from '@/icons/IconStarOutline';
import { getProjectsSyncStatus } from '@/state/state';
import IconCloudCircle from '@/icons/IconCloudCircle';
import IconCloudOffOutline from '@/icons/IconCloudOffOutline';

import { humanizeDate } from '@/filters/dateFilter';
import { combineLatest } from 'rxjs';
import IconCloudAlert from '@/icons/IconCloudAlert';
import IconCloudCheck from '@/icons/IconCloudCheck';
import IconCloudRefresh from '@/icons/IconCloudRefresh';
import IconCloudOutline from '@/icons/IconCloudOutline';
import { error, info } from '@/features/toasts/toats.service';
import AppUpgradeButton from '@/components/appUpgradeButton/AppUpgradeButton.vue';
import { isDeliveryContactOnly } from '@/features/deliveryContacts/deliveryContacts.service';
import IconLifebuoy from '@/icons/IconLifebuoy.vue';
import IconEmailOutline from '@/icons/IconEmailOutline.vue';
import Support from '@/features/projects/Support.vue';

export default {
    components: {
        Support,
        IconEmailOutline,
        IconLifebuoy,
        AppUpgradeButton,
        IconCloudOutline,
        IconCloudRefresh,
        IconCloudCheck,
        IconCloudAlert,
        IconCloudOffOutline,
        IconCloudCircle,
        IconStarOutline,
        IconStar,
        AppSeparator,
        AppPopup,
        AppFilter,
        AppButton,
        AppMail,
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyUp);
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.onKeyUp);
    },
    async created() {
        this.subscriptions = [
            combineLatest([getProjects(), getProjectsSyncStatus()]).subscribe(([projects, syncStates]) => {
                this.projects = projects.map((project) => ({
                    ...project,
                    syncState: syncStates.find((state) => state.projectId === project.id) || {
                        state: 'unknown',
                    },
                }));
                this.loading = false;
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
            projects: [],
            filterAsString: '',
            loading: true,
            escCount: 0,
            window,
        };
    },
    computed: {
        filteredProjects() {
            let result = this.projects.filter((project) => project.me.allowedFeatures.length > 0);
            if (this.filterAsString && this.filterAsString.length > 0) {
                result = result.filter((project) =>
                    filterMatch(
                        project.code + ' ' + project.name + ' ' + project.city,
                        this.filterAsString.trim(),
                        true,
                    ),
                );
            }
            let isFirstNotFavorite = false;
            // favorite are sorted before any other else but still sorted by code/name/city
            return sortBy(result, (project) =>
                project.isFavorite ? 'A' : 'Z' + project.code + ' ' + project.name + ' ' + project.city,
            ).map((project) => {
                if (project.isFavorite || isFirstNotFavorite) {
                    return project;
                } else {
                    isFirstNotFavorite = true;
                    return { ...project, isFirstNotFavorite };
                }
            });
        },
    },
    methods: {
        openHelpPopup() {
            this.$refs.helpPopup.open();
        },
        humanizeDate,
        isDeliveryContactOnly(user) {
            return isDeliveryContactOnly(user);
        },
        isCompany(user) {
            return user.bundleIds.length > 0 && user.allowedFeatures.includes('project_company');
        },
        syncProject(projectId) {
            if (navigator.onLine) {
                database.replicateProject(projectId);
                info(this.$t('sync.syncStart'));
            } else {
                error(this.$t('sync.syncCantStart'));
            }
        },
        removeFromFavorite(project) {
            return updateProject({ id: project.id, isFavorite: false });
        },
        addToFavorite(project) {
            return updateProject({ id: project.id, isFavorite: true });
        },
        onFilterInput(filterValue) {
            if (filterValue === '     ' /* 5 spaces */) {
                this.$refs.debugPopup.open();
            }
        },
        navigateToNewProject() {
            this.$router.push({ name: 'newProjectShort' });
        },
        async exportBackup() {
            const content = await database.exportBackup();
            const blob = new Blob([JSON.stringify(content)], { type: 'application/json' });
            const a = document.createElement('a');
            a.download = 'tex-backup.json';
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = ['application/json', a.download, a.href].join(':');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                URL.revokeObjectURL(a.href);
            }, 1500);
        },
        async reset() {
            if (await confirm(this.$t('projects.resetOfflineDataConfirm'))) {
                await database.reset();
                window.location.reload();
            }
        },
        async onKeyUp(event) {
            if (event.key === 'Esc' || event.key === 'Escape') {
                this.escCount++;
                if (this.escCount === 3) {
                    this.$refs.debugPopup.open();
                } else if (this.escCount > 3) {
                    this.escCount = 0;
                }
            } else {
                this.escCount = 0;
            }
        },
    },
};
</script>
