<template>
    <app-mail-popup ref="popup" label="Envoyer le planning">
        <div>
            <app-select
                :value="selectedMailTemplateId"
                @change="onSelectedMailTemplateChanged"
                :label="$t('project.diffusion.chooseMailTemplateLabel')"
            >
                <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">{{ mailTemplate.name }}</option>
            </app-select>
        </div>
    </app-mail-popup>
</template>

<script>
import AppMailPopup from './AppMailPopup.vue';
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';

export default {
    components: {
        AppSelect,
        AppMailPopup,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'planning');
            }),
        ];
    },
    methods: {
        open(planningExport) {
            this.planningExport = planningExport;
            this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
            this.$refs.popup.open();
        },
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            return string.replaceAll('NomPlanning', this.planningExport.name).replaceAll(
                'LienPlanning',
                `<a href="https://app.tex.moe/api/files/' +
                        ${this.$route.params.projectId}/${this.planningExport.path}">${this.planningExport.fileName}</a>`,
            );
        },
        replaceRecipientTokens(recipientIds = []) {
            return recipientIds.reduce(
                (acc, bundleId) =>
                    bundleId === 'planningExport.filter.bundles'
                        ? [
                              ...acc,
                              ...this.planningExport.filter
                                  .filter((item) => item.criteriaType === 'Lots')
                                  .map((item) => item.id),
                          ]
                        : [...acc, bundleId],
                [],
            );
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: this.replaceRecipientTokens(template.to),
                copyIds: this.replaceRecipientTokens(template.cc),
                attachments: [
                    {
                        fileName: this.planningExport.fileName,
                        url: `/api/files/${this.$route.params.projectId}/${this.planningExport.path}`,
                    },
                ],
            });
        },
    },
    data() {
        return {
            cacheKey: 'planningExport.lastMailTemplate.' + this.$route.params.projectId,
            planningExport: null,
            subscriptions: [],
            mailTemplates: [],
            selectedMailTemplateId: null,
        };
    },
};
</script>
