import { applyGroupDates, treeToLines } from '@/features/planning/planning2/planning2.service';

const defaultBundle = { id: 'orphans', name: 'Tâches non assignées', reference: '' };

export function toTaskBSLBundleNode(bundle) {
    return {
        ...bundle,
        name:
            bundle.type === 'company'
                ? (bundle.reference ? bundle.reference.padStart(3, '0') + ' - ' : '') + bundle.name
                : (bundle.reference ? bundle.reference + ' - ' : '') + bundle.name,
        type: 'bundle',
        class: 'text-blue-600',
        level: 0,
        children: [],
    };
}

export function toTaskBSL(branches, definedBundles) {
    const bundles = [defaultBundle, ...definedBundles];
    return treeToLines(
        applyGroupDates(
            bundles
                .map((bundle) => {
                    const bundleNode = toTaskBSLBundleNode(bundle);
                    const bundleBranches = branches.filter(
                        (task) =>
                            (task.bundleId && bundle.id === task.bundleId) ||
                            (!task.bundleId && bundle.id === defaultBundle.id),
                    );
                    appendBranches(bundleNode, bundleBranches);
                    return bundleNode;
                })
                .filter((node) => node.children.length > 0),
        ),
    );
}
export function isBundleNode(bundle, node) {
    return (
        (node.type === 'bundle' && node.bundleId === bundle.id) ||
        (bundle.reference + ' ' + bundle.name).includes(node.name)
    );
}
export function appendBranches(bundleNode, branches) {
    const cache = {};
    for (const branch of branches) {
        appendBranch(bundleNode, branch, bundleNode, cache);
    }
}
export function appendBranch(parentNode, nodeRest, bundle, cache) {
    const node = nodeRest[0];
    let line = cache[node.id] || cache[bundle.id + node.id];
    if (!line) {
        if (!isBundleNode(bundle, node)) {
            const newNode = {
                ...node,
                id: nodeRest.length === 1 ? node.id : bundle.id + node.id,
                level: parentNode.level + 1,
                children: [],
            };
            cache[newNode.id] = newNode;
            parentNode.children.push(newNode);
            if (nodeRest.length > 1) {
                nodeRest.shift();
                appendBranch(newNode, nodeRest, bundle, cache);
            }
        } else {
            if (nodeRest.length > 1) {
                nodeRest.shift();
                appendBranch(parentNode, nodeRest, bundle, cache);
            }
        }
    }
    if (nodeRest.length > 1) {
        nodeRest.shift();
        appendBranch(line, nodeRest, bundle, cache);
    }
}

export default {
    toTaskBSL,
};
