<template>
    <div>
        <app-autocomplete
            :options="optionsWithIds"
            ref="autocomplete"
            :overlay="false"
            @input="execute($event)"
            popupClass="right-0 bottom-0 border shadow md:w-1/6"
            v-model="value"
            transition="quick-action"
            :label-function="labelFunction"
            :strict-matching="false"
        >
            <template v-slot:handle>
                <div
                    class="text-xs p-1 bg-white flex gap-2 border fixed bottom-0 right-0"
                    v-if="optionsWithIds.length > 0"
                >
                    <icon-flash width="16" height="16"></icon-flash>
                    <a href="#" @click="open" class="underline">
                        {{ $t('quickActions.title') }} {{ $t('quickActions.shortcutLabel') }}
                    </a>
                </div>
            </template>
            <template v-slot:title>
                <div class="flex justify-between text-xs p-2 bg-main text-white">
                    <div class="flex gap-2 justify-center items-center">
                        <icon-flash width="16" height="16"></icon-flash>
                        {{ $t('quickActions.title') }}
                    </div>

                    <app-button size="mini" icon="icon-close" @click="$refs.autocomplete.close()"></app-button>
                </div>
            </template>
            <template v-slot:item="{ item, filter }">
                <div class="flex py-2 gap-2 justify-between w-full items-center">
                    <span>{{ labelFunction(item, filter) }}</span>
                    <span v-if="item.tip" class="text-xs text-gray-700 italic">{{ item.tip }}</span>
                </div>
            </template>
        </app-autocomplete>
    </div>
</template>
<script>
import AppAutocomplete from '../app-autocomplete/AppAutocomplete';
import AppButton from '../appButton/AppButton';
import hotkeys from 'hotkeys-js';
export default {
    components: { AppButton, AppAutocomplete },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        hotkeys('ctrl+space', { capture: true }, this.handleShortcut);
    },
    beforeDestroy() {
        hotkeys.unbind('ctrl+space');
    },
    watch: {
        options(options) {
            this.value = options[0];
        },
    },
    methods: {
        labelFunction(item, filter) {
            if (typeof item.name === 'function') {
                return item.name(item, filter);
            } else {
                return item.name;
            }
        },
        open() {
            if (this.$refs.autocomplete) {
                this.$refs.autocomplete.open();
            }
        },
        execute(item) {
            this.$emit('choose', item);
        },
        handleShortcut(event) {
            if (!event.altKey && event.ctrlKey && !event.metaKey && !event.shiftKey && event.code === 'Space') {
                this.open();
            }
        },
    },
    data() {
        return { value: this.options ? this.options[0] : null };
    },
    computed: {
        optionsWithIds() {
            return this.options ? this.options.map((option, index) => ({ ...option, id: index })) : [];
        },
    },
};
</script>
<style scoped>
.quick-action-enter-active {
    transition: all 0.3s ease;
}
.quick-action-leave-active {
    transition: all 0.3s ease;
}
.quick-action-enter,
.quick-action-leave-to {
    transform: translateX(40px) translateY(40px);
    opacity: 0;
}
</style>
