<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <AppLegend />
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="certificate.name"
                        class="col-span-2 md:col-span-1"
                        :required="true"
                        :disabled="!isAdmin"
                        @blur="save()"
                    />
                    <app-input-text
                        :label="$t('certificates.code')"
                        v-model="certificate.code"
                        class="col-span-2 md:col-span-1"
                        :disabled="!isAdmin"
                        @blur="save()"
                    />
                    <app-bundle-picker
                        class="col-span-2"
                        v-model="certificate.emitter"
                        :options="bundles"
                        :disabled="!isAdmin"
                        @input="save()"
                    ></app-bundle-picker>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('commons.predecessors')">
                    <div class="flex flex-col col-span-2">
                        <app-multi-picker
                            :value="certificate.taskPredecessors"
                            @check="createPredecessors($event)"
                            @uncheck="removePredecessorsFromTaskId($event)"
                            :options="tasksOptions"
                            variant="button"
                            :show-label="false"
                            :show-input="false"
                            :label="$t('commons.newPredecessor')"
                            :title="$t('commons.newPredecessor')"
                            :disabled="!isAdmin"
                        ></app-multi-picker>
                        <table class="flex-grow mb-10 text-sm">
                            <tr v-if="certificate && certificate.predecessors.length === 0">
                                <td colspan="3">{{ $t('commons.noPredecessor') }}</td>
                            </tr>
                            <template v-for="task in certificate.taskPredecessors">
                                <tr :v-key="task.id">
                                    <td class="px-2 py-1">
                                        <app-drop-down-button
                                            :label="task.typeLabel"
                                            :show-label="false"
                                            :icon="'icon-gantt' + task.type.toLowerCase()"
                                            :title="$t('services.predecessorType' + task.type)"
                                            @input="
                                                updateCertificatePredecessor({
                                                    taskId: task.taskId,
                                                    type: $event,
                                                })
                                            "
                                            v-model="task.type"
                                            :disabled="!isAdmin"
                                        >
                                            <option value="ES">
                                                {{ $t('services.predecessorTypeES') }}
                                            </option>
                                            <option value="SS">
                                                {{ $t('services.predecessorTypeSS') }}
                                            </option>
                                        </app-drop-down-button>
                                    </td>
                                    <td class="px-2 py-1">
                                        <div>{{ task.label }}</div>
                                    </td>
                                    <td class="px-2 py-1">
                                        <div v-if="task.type === 'ES'" class="flex flex-no-wrap gap-1">
                                            <span v-html="'\u21E5'" />
                                            {{ task.estimatedEndDate | humanizeDate }}
                                        </div>
                                        <div v-else-if="task.type === 'SS'" class="flex flex-no-wrap gap-1">
                                            {{ (task.realStartDate || task.startDate) | humanizeDate }}
                                            <span v-html="'\u21A6'" />
                                        </div>
                                    </td>
                                    <td class="px-2 py-1">
                                        <div class="flex items-center">
                                            <app-button
                                                size="mini"
                                                @click.prevent="removePredecessorsFromTaskId([task])"
                                                icon="icon-trash-can-outline"
                                                variant="danger"
                                                aria-label="delete predecessor"
                                                :disabled="!isAdmin"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-5" :label="$t('certificates.productionDates')">
                    <app-date-input
                        v-model="certificate.emissionDueDate"
                        class="col-span-2 md:col-span-1"
                        :label="$t('certificates.emissionDueDate')"
                        :warning="certificate.emissionDueDate > recommendedEmissionDate"
                        :disabled="!isAdmin"
                        @input="save()"
                    ></app-date-input>

                    <app-date-input
                        v-model="certificate.emissionDate"
                        class="col-span-2 md:col-span-1"
                        :label="$t('certificates.emissionDate')"
                        :disabled="!isAdmin"
                        @input="save()"
                    />
                    <div
                        class="col-span-2 flex items-center px-2 text-xs border"
                        v-if="
                            !certificate.emissionDate &&
                            recommendedEmissionDate &&
                            isAdmin &&
                            recommendedEmissionDate > Date.now()
                        "
                        :class="certificate.emissionDueDate > recommendedEmissionDate ? 'bg-yellow-100' : 'bg-green-50'"
                    >
                        {{ $t('certificates.recommendedDate') }}
                        <app-button @click="applyRecommendedDate" size="mini" variant="none" class="m-1">
                            {{ recommendedEmissionDate | humanizeDate }}
                        </app-button>
                    </div>
                    <div
                        class="flex col-span-2 items-center bg-yellow-100 p-2 border"
                        v-else-if="!certificate.emissionDate"
                    >
                        {{ $t('certificates.sendAsap') }}
                    </div>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.status')">
                    <certificate-status :certificate="certificate" @remove="removeCertificate" @input="saveStatus" />
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('certificates.attachments')">
                    <app-multiple-upload-button
                        v-if="isAdmin || isEmitter"
                        class="col-span-2 text-sm offline:hidden"
                        icon="icon-paperclip"
                        :label="$t('certificates.newAttachment')"
                        :end-point="`/api/projects/${$route.params.projectId}/certificates/${certificate.id}/attachment`"
                        method="PUT"
                        :name="$t('certificates.newAttachment')"
                    />
                    <table class="col-span-2">
                        <template v-for="attachment in certificate.attachments">
                            <tr :v-key="attachment.id" class="border-b hover:bg-gray-200">
                                <td>
                                    <app-file-link
                                        :fileName="attachment.fileName"
                                        :url="attachment.url"
                                        :to="{
                                            name: 'fileViewer',
                                            params: {
                                                projectId: $route.params.projectId,
                                            },
                                            query: {
                                                url: attachment.url,
                                                fileName: attachment.fileName,
                                                backName: $t('certificates.title'),
                                            },
                                        }"
                                    ></app-file-link>
                                </td>
                                <td class="p-2" style="width: 30px">
                                    <app-button
                                        size="mini"
                                        variant="danger"
                                        aria-label="remove document"
                                        icon="icon-trash-can-outline"
                                        @click="onDeleteAttachment(attachment)"
                                        v-if="isAdmin"
                                    />
                                </td>
                            </tr>
                        </template>
                    </table>
                </app-fieldset>
                <app-save-on-leave
                    :dirty="dirty"
                    :saveFn="() => save(true)"
                    v-if="isAdmin || isEmitter"
                ></app-save-on-leave>
            </form>
        </ValidationObserver>
        <app-quick-actions
            :options="quickActions"
            @choose="$event.run()"
            v-if="isAdmin || isEmitter"
        ></app-quick-actions>
    </main>
</template>

<script>
import AppLegend from '../../components/appLegend/AppLegend';
import { getMapById, sanitize, sortBy, uniqBy } from '@/services/sanitize.service';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppButton from '../../components/appButton/AppButton';
import AppBundlePicker from '../../components/appBundlePicker';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import { confirm } from '../dialogs/dialogs.service';

import {
    createCertificatePredecessors,
    getCertificate,
    removeCertificate,
    removeCertificatePredecessors,
    updateCertificate,
    updateCertificatePredecessor,
} from './certificates.service';
import AppFileLink from '../../components/appFileLink/AppFileLink';
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AppBackButton from '../../components/appBackButton/AppBackButton';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { getServices } from '@/features/services/services.service';
import { getTasks } from '@/features/tasks/tasks.service';
import { getProject } from '@/features/projects/projects.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { reportError } from '@/features/tracker/tracker.service';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';
import IconArrowAll from '@/icons/IconArrowAll.vue';
import CertificateStatus from '@/features/certificates/CertificateStatus.vue';

export default {
    components: {
        CertificateStatus,
        IconArrowAll,
        AppDropDownButton,
        AppMultipleUploadButton,
        AppSaveOnLeave,
        AppBackButton,
        AppQuickActions,
        AppFileLink,
        AppMultiPicker,
        AppBundlePicker,
        AppButton,
        AppPicker,
        AppDateInput,
        AppErrors,
        AppFieldset,
        AppInputText,
        AppLegend,
    },
    created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        locations() {
            const result = [];
            if (this.certificate.bundle && this.certificate.serviceId) {
                for (const task of this.taskMapByServiceId[this.certificate.serviceId]) {
                    result.push(task.location);
                }
            }
            return uniqBy(result, 'id');
        },
        recommendedEmissionDate() {
            if (this.certificate.taskPredecessors.length > 0) {
                return new Date(
                    this.certificate.taskPredecessors
                        .map((taskPredecessor) => {
                            if (taskPredecessor.type === 'ES') {
                                return taskPredecessor.estimatedEndDate?.getTime();
                            } else if (taskPredecessor.type === 'SS') {
                                return (taskPredecessor.realStartDate || taskPredecessor.startDate)?.getTime();
                            }
                        })
                        .filter((a) => !!a)
                        .sort()
                        .pop(),
                );
            } else null;
        },
        tasksOptions() {
            return sortBy(
                this.planning.map((task) => ({
                    ...task,
                    name: task.service.bundle.label + ' > ' + task.location.fullName + ' > ' + task.name,
                })),
                'name',
            );
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId).pipe(
                        map((folders) => locationService.getLocationMap(folders)),
                    ),
                    getCertificate(this.$route.params.projectId, this.$route.params.certificateId),
                    getServices(this.$route.params.projectId).pipe(map((services) => getMapById(services))),
                    getTasks(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                    getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                ]).subscribe(([bundleMap, locationMap, certificate, serviceMap, tasks, project, planning]) => {
                    updateBreadCrumbs({ certificateName: certificate.name });
                    this.serviceMap = serviceMap;
                    this.planning = planning;
                    this.tasks = tasks
                        .map((task) => ({
                            ...task,
                            service: this.serviceMap[task.serviceId],
                            location: locationMap[task.locationId],
                        }))
                        .filter((task) => task.service);
                    this.taskMapByServiceId = this.tasks.reduce(
                        (acc, task) => ({
                            ...acc,
                            [task.serviceId]: acc[task.serviceId] ? [...acc[task.serviceId], task] : [task],
                        }),
                        {},
                    );
                    this.locationMap = locationMap;
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.certificate = {
                        ...certificate,
                        bundle: bundleMap[certificate.bundleId],
                        locations: certificate.locationIds.map((id) => this.locationMap[id]),
                        service: this.serviceMap[certificate.serviceId],
                        emitter: bundleMap[certificate.emitterId],
                        taskPredecessors: sortBy(
                            certificate.predecessors
                                .map((predecessor) => {
                                    const task = planning.find((aTask) => aTask.id === predecessor.taskId);
                                    if (task) {
                                        return {
                                            ...task,
                                            ...predecessor,
                                            label:
                                                task.service.bundle.label +
                                                ' > ' +
                                                task.location.fullName +
                                                ' > ' +
                                                task.name,
                                        };
                                    } else {
                                        reportError(
                                            'missing task ' +
                                                predecessor.taskId +
                                                ' referenced by ' +
                                                this.certificate.id,
                                        );
                                        return null;
                                    }
                                })
                                .filter((a) => !!a),
                            'label',
                        ),
                    };
                    this.isAdmin = project.me.allowedFeatures.includes('project_certificates');
                    this.isEmitter = project.me.bundleIds.includes(certificate.emitterId);
                }),
            ];
        },
        applyRecommendedDate() {
            this.certificate.emissionDueDate = this.recommendedEmissionDate;
            this.save();
        },
        backToList() {
            this.$router.push({ name: 'certificates', hash: '#uuid_' + this.certificate.id });
        },
        async onDeleteAttachment({ url }) {
            if (await confirm(this.$t('certificates.confirmMessage'))) {
                return updateCertificate(this.$route.params.projectId, {
                    id: this.$route.params.certificateId,
                    attachments: this.certificate.attachments
                        .filter((attachment) => attachment.url !== url)
                        .map((attachment) => ({ url: attachment.url, fileName: attachment.fileName })),
                });
            }
        },
        getAllowedLocation(serviceId) {
            let bundleTasks = this.tasks.filter((task) => serviceId === task.serviceId);
            return uniqBy(bundleTasks, 'locationId').map((task) => this.locationMap[task.locationId]);
        },
        async save() {
            const certificateEntity = {
                ...sanitize(this.certificate, ['bundle', 'emitter', 'locations', 'service', 'taskPredecessors']),
                emitterId: this.certificate.emitter ? this.certificate.emitter.id : null,
                bundleId: this.certificate.bundle ? this.certificate.bundle.id : null,
                serviceId: this.certificate.service ? this.certificate.service.id : null,
                locationIds: this.certificate.locations.map((location) => location.id),
            };
            await updateCertificate(this.$route.params.projectId, {
                id: this.certificate.id,
                ...certificateEntity,
            });
            if (this.$refs.observer) {
                this.$refs.observer.reset();
            }
        },
        saveStatus(status) {
            return updateCertificate(this.$route.params.projectId, status);
        },
        createPredecessors(tasks) {
            return createCertificatePredecessors(
                this.$route.params.projectId,
                this.certificate,
                tasks.map((task) => ({
                    taskId: task.id,
                    type: 'ES',
                    delay: 0,
                })),
            );
        },
        async removePredecessorsFromTaskId(tasks) {
            return removeCertificatePredecessors(
                this.$route.params.projectId,
                this.certificate,
                tasks.map((task) => task.id),
            );
        },
        async updateCertificatePredecessor(predecessor) {
            return updateCertificatePredecessor(this.$route.params.projectId, this.certificate, predecessor);
        },
        async removeCertificate() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await removeCertificate(this.$route.params.projectId, this.certificate.id);
                this.$router.push({
                    name: 'followCertificates',
                    params: {
                        projectId: this.$route.params.projectId,
                    },
                });
            }
        },
    },
    data() {
        return {
            isAdmin: false,
            isEmitter: false,
            bundles: [],
            agenda: [],
            subscriptions: [],
            planning: [],
            taskMapByServiceId: {},
            serviceMap: {},
            certificate: {
                name: this.$t('certificates.newName'),
                bundle: null,
                code: '',
                locations: [],
                emissionDueDate: null,
                emissionDate: null,
                attachments: [],
                predecessors: [],
                taskPredecessors: [],
            },
            quickActions: [
                {
                    name: this.$t('certificates.newCertificate'),
                    run: () =>
                        this.$router.push({
                            name: 'followCertificate',
                            params: {
                                projectId: this.$route.params.projectId,
                                certificateId: 'new',
                            },
                        }),
                },
            ],
        };
    },
};
</script>
