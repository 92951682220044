<template>
    <main ref="main" class="h-full w-main relative min-h-main max-h-main flex flex-col p-2" @click="onClick">
        <div v-if="loading" class="flex justify-center">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
        <template v-else>
            <div ref="planningToolbar" class="flex text-xs gap-2" v-if="plannings.length">
                <input
                    ref="filePickerMsProject"
                    type="file"
                    class="hidden"
                    accept="application/xml"
                    tabindex="-1"
                    v-on:change="onPickMSProjectFile"
                />
                <input
                    ref="filePickerGanttProject"
                    type="file"
                    class="hidden"
                    accept="text/csv"
                    tabindex="-1"
                    v-on:change="onPickGanttProjectFile"
                />
                <app-select
                    :label="$t('planning.selectedPlanning')"
                    v-model="selectedPlanningId"
                    @input="onPlanningSelect"
                >
                    <option :value="null"></option>
                    <template v-for="planning in plannings">
                        <option :value="planning.id">
                            {{ planning.name }}
                        </option>
                    </template>
                    <option value="new">- {{ $t('planning.newPlanning') }} -</option>
                    <option value="importGanttProject">- {{ $t('planning.importPlanningGantt') }} -</option>
                    <option value="importMsProject">- {{ $t('planning.importPlanningMsProject') }} -</option>
                </app-select>
                <app-select :label="$t('planning.structure')" v-model="structure" @input="updateState">
                    <option value="free">{{ $t('planning.free') }}</option>
                    <option value="BSL">{{ $t('planning.BSL') }}</option>
                    <option value="LBS">{{ $t('planning.LBS') }}</option>
                </app-select>
                <div class="flex items-end">
                    <app-button
                        :label="$t('planning.newTask')"
                        icon="icon-plus"
                        size="mini"
                        @click="newTask"
                    ></app-button>
                </div>
            </div>
            <div v-if="plannings.length === 0" class="flex justify-center w-100 p-5">
                <app-button
                    :label="$t('planning.newPlanning')"
                    @click="$refs.newPlanning.open()"
                    variant="primary"
                ></app-button>
            </div>
            <gantt2
                v-if="!taskLoading"
                ref="gantt"
                :tasks="lines"
                :options="options"
                @select="onSelect"
                @open="onOpen"
                @contextMenu="onContextMenu"
                :selectedItem="selectedItem"
                :style="{ 'max-height': ganttMaxHeight, height: ganttMaxHeight }"
                class="px-1 text-xs"
                :agenda="agendaWithWeekEnds"
                :minDate="options.minDate"
                :maxDate="options.maxDate"
                @navigateToDate="navigateToDate($event)"
                @expand="expand"
                @expandDeep="expandDeep"
                @collapseDeep="collapseDeep"
                @collapse="collapse"
                @updateDuration="updateTaskDuration"
                @updateStartDate="updateTaskStartDate"
                @updateEndDate="updateTaskEndDate"
                @updateProgress="updateTaskProgress"
                @updateRealStartDate="updateTaskRealStartDate"
                @updateRealEndDate="updateTaskRealEndDate"
                @itemNameFocus="onItemNameFocus"
                @itemNameBlur="onItemNameBlur"
                @updatedName="onRenameTask"
                @bundleFocus="$refs.bundleMenu.open()"
            ></gantt2>
            <hsc-menu-style-white>
                <hsc-menu-context-menu ref="contextMenu">
                    <div></div>
                    <template slot="contextmenu">
                        <hsc-menu-item v-if="selectedItem" @click.native="addService(selectedItem.type === 'bundle')">
                            <template v-slot:body>
                                <div class="flex w-full justify-between gap-2 items-end">
                                    <span>{{ $t('planning.newService') }}</span>
                                    <i class="text-xs font-mono italic text-gray-500">alt+=</i>
                                </div>
                            </template>
                        </hsc-menu-item>
                        <hsc-menu-item
                            :label="$t('planning.addSubTask')"
                            v-if="selectedItem && selectedItem.type !== 'bundle'"
                            @click="addService(true)"
                        />
                        <hsc-menu-item
                            :label="$t('planning.editLocations')"
                            v-if="selectedItem"
                            @click="addLocations"
                        />
                        <hsc-menu-separator />
                        <!--
                        <hsc-menu-item :label="$t('commons.copy')" v-if="selectedItem" @click="copyNode" />
                        <hsc-menu-item :label="$t('commons.paste')" v-if="selectedItem" @click="pasteNode" />
                        <hsc-menu-item
                            :label="$t('commons.duplicate')"
                            v-if="selectedItem && selectedItem.type !== 'location' && selectedItem.type !== 'bundle'"
                            @click="duplicateNode"
                        />-->
                        <hsc-menu-item
                            :disabled="selectedItem && selectedItem.type === 'bundle'"
                            @click.native="removeNode"
                        >
                            <template v-slot:body>
                                <div class="flex w-full justify-between gap-2 items-end">
                                    <span>{{ $t('commons.removeShort') }}</span>
                                    <i class="text-xs font-mono italic text-gray-500">alt+backspace</i>
                                </div>
                            </template>
                        </hsc-menu-item>
                    </template>
                </hsc-menu-context-menu>
            </hsc-menu-style-white>
            <app-autocomplete
                ref="bundleMenu"
                :options="bundleOptions"
                @input="onBundle"
                :filter-string-function="(item) => item.reference + item.name"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.reference + ' - ' + item.name }}</span>
                </template>
            </app-autocomplete>
            <app-autocomplete
                ref="locationMenu"
                :options="locationOptions"
                @input="onLocation"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName || item.name"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
            </app-autocomplete>
            <app-autocomplete
                ref="locationsMenu"
                :multiple="true"
                :options="locationOptions"
                @close="onLocations"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName"
                v-model="selectedItemLocations"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
                <template v-slot:footer>
                    <app-footer @click="$refs.locationsMenu.close()" class="m-2"></app-footer>
                </template>
            </app-autocomplete>
        </template>
        <app-popup ref="newPlanning" :title="$t('planning.newPlanning')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text v-model="newPlanning.name" :label="$t('commons.name')"></app-input-text>
                    <app-footer
                        @click="
                            $refs.newPlanning.close();
                            createPlanning({ name: newPlanning.name });
                            newPlanning.name = '';
                        "
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.newPlanning.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="taskPopup" :title="$t('planning.taskDetail')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text v-model="editedTask.name" :label="$t('commons.name')"></app-input-text>
                    <app-bundle-picker
                        :options="bundles"
                        v-model="editedTask.bundle"
                        :label="$t('bundles.bundle')"
                    ></app-bundle-picker>
                    <app-picker
                        :value="editedTask.location"
                        :options="locationOptions"
                        :show-label="true"
                        :label="$t('services.locations')"
                        label-key="fullName"
                        v-model="onSelectedLocation"
                    />

                    <app-footer
                        @click="$refs.newPlanning.close()"
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.newPlanning.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="newTasksPopup" :title="$t('planning.newTask')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text
                        v-model="newTasks.name"
                        :label="$t('planning.taskName')"
                        :required="true"
                    ></app-input-text>
                    <app-multi-picker
                        ref="bundles"
                        v-model="newTasks.bundles"
                        :options="bundles"
                        :label="$t('planning.responsible')"
                        :required="true"
                        label-key="label"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option" />
                        </template>
                        <template v-slot:footer="{ popup }">
                            <div class="flex justify-end p-1">
                                <app-button
                                    variant="primary"
                                    :label="$t('commons.next')"
                                    @click="$refs.bundles.close"
                                ></app-button>
                            </div>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        v-model="newTasks.locations"
                        :options="locationOptions"
                        :label="$t('services.locations')"
                        label-key="fullName"
                        ref="locations"
                    >
                        <template v-slot:footer="{ popup }">
                            <div class="flex justify-end p-1">
                                <app-button
                                    variant="primary"
                                    :label="$t('commons.next')"
                                    @click="$refs.locations.close"
                                ></app-button>
                            </div>
                        </template>
                    </app-multi-picker>
                    <app-number-input
                        v-model="newTasks.duration"
                        :show-tips="false"
                        format="integer"
                        :label="$t('project.follow.planning.duration')"
                    ></app-number-input>
                    <div>
                        <app-label :label="$t('project.follow.planning.predecessors')" class="mt-2"></app-label>
                        <TaskPredecessors2 :task="newTasks" :tasks="tasks" />
                    </div>
                    <app-footer
                        @click="createTasks()"
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid || newTasks.bundles.length === 0"
                    ></app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import Gantt2 from '../../../components/gantt/Gantt2';
import AppSelect from '../../../components/appSelect/AppSelect';
import { isInViewport, sortBy } from '@/services/sanitize.service';
import {
    applyCollapse,
    cleanOrDefineMaxDate,
    getWeekEnds,
    mapAgenda,
    mapTasks2,
} from '@/components/gantt/ganttService';
import hotkeys from 'hotkeys-js';
import startOfDay from 'date-fns/startOfDay';
import vueClickHelper from 'vue-click-helper';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getReferences } from '@/features/planning/references/references.service';
import planning2Service from './planning2.service';
import planning2BSLService from './planning2BSL.service';
import planning2LBSService from './planning2LBS.service';
import planning2FreeService from './planning2Free.service';
import TaskPredecessors2 from './TaskPredecessors2';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppDateInput from '@/components/appDateInput/AppDateInput.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import locationService from '@/services/location.service';
import { queryPlanningState, updatePlanningState } from '@/state/state';
import format from 'date-fns/format';
import { mapLocationsAndBundles } from '@/features/planning/planning2/planning2Mapping.service';
import AppBundlePicker from '@/components/appBundlePicker.vue';
import AppPicker from '@/components/appPicker/AppPicker.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AppLabel from '@/components/appLabel/AppLabel.vue';

export default {
    components: {
        AppLabel,
        AppNumberInput,
        AppBundle,
        AppMultiPicker,
        AppPicker,
        AppBundlePicker,
        AppAutocomplete,
        AppPopup,
        AppDateInput,
        AppInputText,
        AppButton,
        AppCheckbox,
        AppFooter,
        AppTips,
        AppSelect,
        Gantt2,
        TaskPredecessors2,
    },
    directives: { 'click-helper': vueClickHelper },
    async created() {
        const projectId = this.$route.params.projectId;
        queryProject(projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
        this.restorePlanningState();

        this.options.showBundleColumn = this.structure === 'free';
        this.plannings = await planning2Service.getPlannings(projectId);
        if (this.selectedPlanningId === null && this.plannings.length > 0) {
            this.selectedPlanningId = this.plannings[0].id;
        }
        this.subscriptions = [
            combineLatest([
                getBundles(projectId),
                getCalendar(projectId),
                getLocationsTree(projectId),
                getReferences(projectId),
            ]).subscribe(async ([bundles, agenda, folders, references]) => {
                this.bundles = bundles;
                this.agenda = agenda;
                this.folders = folders;
                this.references = references;

                if (this.plannings[0]?.id) {
                    await this.loadTasks();
                    try {
                        this.tasks = mapTasks2(this.buildTaskTree());
                    } catch (e) {
                        //nop
                    }
                }
                this.loading = false;
            }),
        ];
    },
    watch: {
        structure() {
            this.tasks = mapTasks2(this.buildTaskTree());
            this.options.showBundleColumn = this.structure === 'free';
        },
    },
    updated: function () {
        this.updated();
    },
    data() {
        return {
            newTasks: { bundles: [], locations: [], duration: 1, predecessors: [] },
            filterOptions: [],
            filterAsArray: [],
            tasks: [],
            selectedItemLocations: [],
            contextMenuOpen: false,
            activeContextMenuOptions: [{ name: 'test1' }, { name: 'test3' }],
            bundleContextMenuOptions: [],
            serviceContextMenuOptions: [],
            locationContextMenuOptions: [],
            newPlanning: { name: this.$t('planning.newPlanningName') },
            selectedPlanningId: null,
            plannings: [],
            plannedTaskTrigger: new BehaviorSubject(new Date()),
            bundles: [],
            folders: [],
            agenda: [],
            loading: true,
            taskLoading: true,
            readOnly: true,
            structure: 'free',
            DBTasks: [],
            selectedItem: null,
            project: {},
            ganttMaxHeight: '100%',
            maxLevel: '99',
            options: {
                print: false,
                scale: 'months',
                name: '',
                fileName: null,
                maxDate: new Date(new Date().getTime() + 1000 * 24 * 30),
                minDate: new Date(),
                showCollapseButtons: true,
                showLineNumbers: false,
                showProgressLine: false,
                showReference: false,
                refDate: new Date(),
                showReal: false,
                showPlanned: false,
                progressReportedTo: 'reference',
                showReferenceData: false,
                showRealData: true,
                showLateColumn: true,
                showQuantityColumn: false,
                showBundleColumn: false,
                showDurationColumn: false,
                showPlannedData: true,
                showProgressColumn: true,
                showOutOfRangeLines: false,
                considerFilter: true,
                considerCollapsed: true,
                sortKey: 'date',
            },
            lastPlannedDate: null,
            references: [],
            collapsedLines: {},
            referenceId: null,
        };
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', this.onResize);
        hotkeys('down,ctrl+down', this.selectNextTask);
        hotkeys('up,ctrl+up', this.selectPreviousTask);
        hotkeys('right', this.next);
        hotkeys('left', this.previous);
        hotkeys('shift+alt+=', () => this.addService(true));
        hotkeys('alt+=', () => this.addService(false));
        hotkeys('shift+alt+backspace', () => this.removeNode());
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        hotkeys.unbind('down,ctrl+down');
        hotkeys.unbind('up,ctrl+up');
        hotkeys.unbind('left');
        hotkeys.unbind('right');
        hotkeys.unbind('shift+alt+=');
        hotkeys.unbind('shift+alt+backspace');
        document.body.style.overflow = 'auto';
    },
    computed: {
        lines() {
            return applyCollapse(
                this.tasks.map((task) => ({
                    ...task,
                    collapsed: this.collapsedLines[task.id] === true,
                })),
            );
        },
        locationOptions() {
            return locationService.buildLocationOptions(this.folders);
        },
        bundleOptions() {
            return this.bundles;
        },
        agendaWithWeekEnds() {
            return mapAgenda(
                sortBy([...getWeekEnds(this.options.minDate, this.options.maxDate), ...this.agenda], 'time'),
                this.options.refDate,
            );
        },
    },
    methods: {
        onSelectedLocation() {},
        async updateState() {
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            updatePlanningState(this.$route.params.projectId, {
                selectedPlanningId: this.options.selectedPlanningId,
                scale: this.options.scale,
                structure: this.structure,
                minDate: format(this.options.minDate, 'yyyy-MM-dd'),
                refDate: this.options.refDate.toISOString(),
                selectedItemId: this.selectedItem?.id,
                showProgressLine: this.options.showProgressLine,
                showReference: this.options.showReference,
                showReal: this.options.showReal,
                progressReportedTo: this.options.progressReportedTo,
                showPlanned: this.options.showPlanned,
                showLateColumn: this.options.showLateColumn,
                showQuantityColumn: this.options.showQuantityColumn,
                showDurationColumn: this.options.showDurationColumn,
                showProgressColumn: this.options.showProgressColumn,
                showPlannedData: this.options.showPlannedData,
                showReferenceData: this.options.showReferenceData,
                showRealData: this.options.showRealData,
                sortKey: this.options.sortKey || 'date',
                showOutOfRangeLines: this.options.showOutOfRangeLines,
                filter: this.filterAsArray.map((item) =>
                    item._isStringCriteria ? item : { id: item.id, _isBundleCriteria: item._isBundleCriteria },
                ),
                considerFilter: this.options.considerFilter,
                considerCollapsed: this.options.considerCollapsed,
                collapsedLines: this.collapsedLines,
                referenceId: this.referenceId,
            });
        },
        restorePlanningState() {
            const planningState = queryPlanningState(this.$route.params.projectId);
            if (planningState.filter) {
                this.filterAsArray = [
                    ...this.filterOptions
                        .reduce((acc, item) => [...acc, ...item.children], [])
                        .filter(
                            (criterion) =>
                                !!planningState.filter.find((item) => {
                                    return item.id === criterion.id;
                                }),
                        ),
                    ...planningState.filter.filter((criterion) => criterion._isStringCriteria),
                ];
            }
            this.options.scale = planningState.scale || 'months';
            this.options.minDate = planningState.minDate ? new Date(planningState.minDate) : new Date();
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            this.options.refDate = startOfDay(this.options.refDate || new Date());
            this.options.showProgressLine =
                typeof planningState.showProgressLine === 'boolean' ? planningState.showProgressLine : true;
            this.options.showReference = !!planningState.showReference;
            this.options.showReal = !!planningState.showReal;
            this.options.progressReportedTo = planningState.progressReportedTo || 'reference';
            this.options.showPlanned = !!planningState.showPlanned;
            this.options.showLateColumn = planningState.showLateColumn;
            this.options.showQuantityColumn = planningState.showQuantityColumn;
            this.options.showBundleColumn = planningState.showBundleColumn;
            this.options.showDurationColumn = planningState.showDurationColumn;
            this.options.showProgressColumn = planningState.showProgressColumn;
            this.options.showReferenceData = planningState.showReferenceData;
            this.options.sortKey = planningState.sortKey || 'date';
            this.options.showPlannedData =
                typeof planningState.showPlannedData === 'boolean' ? planningState.showPlannedData : true;
            this.options.showRealData =
                typeof planningState.showRealData === 'boolean' ? planningState.showRealData : true;
            this.options.showOutOfRangeLines =
                typeof planningState.showOutOfRangeLines === 'boolean' ? planningState.showOutOfRangeLines : false;
            this.options.considerCollapsed =
                typeof planningState.considerCollapsed === 'boolean' ? planningState.considerCollapsed : true;
            this.options.considerFilter =
                typeof planningState.considerFilter === 'boolean' ? planningState.considerFilter : true;

            this.structure = planningState.structure || 'free';
            this.referenceId = planningState.referenceId || null;
            this.collapsedLines = planningState.collapsedLines || {};
        },
        buildTaskTree() {
            const branches = mapLocationsAndBundles(
                this.DBTasks.map((a) => ({ ...a })),
                this.bundles,
                this.locationOptions,
            );
            if (this.structure === 'free') {
                return planning2FreeService.toTaskFree(branches, this.DBTasks);
            } else if (this.structure === 'BSL') {
                return planning2BSLService.toTaskBSL(branches, this.bundles);
            } else {
                return planning2LBSService.toTaskLBS(branches, this.locationOptions);
            }
        },
        onRenameTask() {
            this.renameTask();
            const node = this.tasks.find((task) => task.id === this.selectedItem.id);
            node.name = this.selectedItem.name;
            if (node.edited) {
                node.edited = false;
            }
        },
        async renameTask() {
            await planning2Service.updateTask(this.$route.params.projectId, this.selectedPlanningId, {
                id: this.selectedItem.id,
                name: this.selectedItem.name,
            });
        },
        newTask() {
            this.$refs.newTasksPopup.open();
        },
        createTasks() {
            const tasks = [];
            for (const bundle of this.newTasks.bundles) {
                const existingBundleNode = this.DBTasks.find(
                    (task) => task.type === 'bundle' && task.bundleId === bundle.id,
                );
                const bundleNode = existingBundleNode || {
                    id: uuidv4(),
                    parentId: null,
                    name: bundle.label,
                    bundleId: bundle.id,
                    type: 'bundle',
                };
                if (!existingBundleNode) {
                    tasks.push(bundleNode);
                }
                const existingTaskNode = this.DBTasks.find(
                    (task) => task.type === 'node' && task.name === this.newTasks.name && task.bundleId === bundle.id,
                );
                const taskNode = existingTaskNode || {
                    id: uuidv4(),
                    name: this.newTasks.name,
                    parentId: bundleNode.id,
                    bundleId: bundle.id,
                    type: 'node',
                };
                if (!existingTaskNode) {
                    tasks.push(taskNode);
                }

                for (const location of this.newTasks.locations) {
                    const existingLocationNode = this.DBTasks.find(
                        (task) =>
                            task.type === 'location' &&
                            task.name === this.newTasks.name &&
                            task.bundleId === bundle.id &&
                            task.locationId === location.id &&
                            task.parentId === taskNode.id,
                    );
                    const locationNode = existingLocationNode || {
                        id: uuidv4(),
                        name: location.fullName,
                        parentId: taskNode.id,
                        locationId: location.id,
                        bundleId: bundle.id,
                        type: 'location',
                    };
                    if (!existingLocationNode) {
                        tasks.push(locationNode);
                    }
                }
            }
            console.log(tasks);
            if (tasks.length > 0) {
                tasks.map((task) =>
                    planning2Service
                        .createTask(this.$route.params.projectId, this.selectedPlanningId, task)
                        .catch((err) => console.error(err)),
                );
            }
            this.$refs.newTasksPopup.close();
        },
        addService(asChild) {
            let parent;
            if (asChild) {
                parent = this.selectedItem;
            } else {
                parent =
                    this.tasks.find((task) => task.id === this.selectedItem.parentId) ||
                    this.tasks.find((task) => task.id === this.selectedItem.bundleId);
            }
            if (parent.type === 'bundle') {
                const newTask = {
                    id: uuidv4(),
                    bundleId: parent.id,
                    parentId: null,
                    name: this.$t('planning.newServiceName'),
                    type: 'service',
                };
                planning2Service
                    .createTasks(this.$route.params.projectId, this.selectedPlanningId, newTask)
                    .catch((err) => console.error(err));
                const newNode = {
                    ...newTask,
                    level: parent.level + 1,
                    editable: true,
                    children: [],
                };
                parent.children.push(newNode);
                const index = this.tasks.findIndex((task) => task.id === parent.id);
                this.tasks.splice(index + 1, 0, newNode);
            } else {
                const newTask = {
                    id: uuidv4(),
                    bundleId: parent.bundleId,
                    parentId: parent.id,
                    name: this.$t('planning.newServiceName'),
                    type: 'service',
                };
                planning2Service
                    .createTasks(this.$route.params.projectId, this.selectedPlanningId, newTask)
                    .catch((err) => console.error(err));
                const newNode = {
                    ...newTask,
                    level: parent.level + 1,
                    editable: true,
                    edited: true,
                    children: [],
                };
                parent.children.push(newNode);
                const index = this.tasks.findIndex((task) => task.id === parent.id);
                this.tasks.splice(index + 1, 0, newNode);
                this.onSelect(newNode);
            }
        },
        onItemNameFocus() {
            if (this.selectedItem && this.selectedItem.type === 'location') {
                this.$refs.locationMenu.open();
            }
        },
        onItemNameBlur(node) {
            const task = this.tasks.find((task) => task.id === node.id);
            if (task) {
                task.edited = false;
            }
        },
        addLocations() {
            this.$refs.locationsMenu.open();
        },
        async removeNode() {
            const parentNode =
                this.tasks.find((task) => task.id === this.selectedItem.parentId) ||
                this.tasks.find((task) => task.id === this.selectedItem.bundleId);
            planning2Service
                .deleteTasks(this.$route.params.projectId, this.selectedPlanningId, this.selectedItem.id)
                .catch((err) => console.error(err));
            const nodeIndex = this.tasks.findIndex((item) => item.id === this.selectedItem.id);
            this.tasks.splice(nodeIndex, 1);
            if (parentNode) {
                parentNode.children = parentNode.children.filter((node) => node.id !== this.selectedItem.id);
            } else {
                const bundleChildren = this.tasks.find((task) => task.id === this.selectedItem.bundleId);
                if (bundleChildren && bundleChildren.children.length === 1) {
                    bundleChildren.children = [];
                }
            }
        },
        copyNode() {},
        pasteNode() {},
        async duplicateNode_Recursive(node, parentId) {
            const parentNode = this.tasks.find((task) => task.id === parentId);
            const parentIndex = this.tasks.indexOf(parentNode);
            const newTask = await planning2Service.createTasks(this.$route.params.projectId, this.selectedPlanningId, {
                bundleId: node.bundleId,
                parentId,
                name: node.name,
                type: node.type,
            });
            const newNode = {
                ...newTask,
                level: node.level,
                editable: node.editable,
                children: [],
            };
            this.tasks.splice(parentIndex + 1, 0, newNode);
            parentNode.children.push(newNode);
            await Promise.all(node.children.map((child) => this.duplicateNode_Recursive(child, newNode.id)));
        },
        duplicateNode() {
            this.duplicateNode_Recursive(this.selectedItem, this.selectedItem.parentId);
        },
        onLocation(selectedLocation) {
            this.selectedItem.name = selectedLocation.fullName || selectedLocation.name;
            this.selectedItem.locationId = selectedLocation._isStringCriteria ? null : selectedLocation.id;
            this.onRenameTask();
        },
        async onBundle(selectedBundle) {
            this.selectedItem.bundleId = selectedBundle.id;
            this.selectedItem.bundle = { id: selectedBundle.id, reference: selectedBundle.reference };
            await planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id: this.selectedItem.id,
                bundleId: selectedBundle.id,
            });
        },
        onLocations() {
            const parentNode =
                this.selectedItem.type === 'service' || this.selectedItem.type === 'bundle'
                    ? this.selectedItem
                    : this.tasks.find((task) => task.id === this.selectedItem.parentId);

            const locationNodesToCreate = this.selectedItemLocations.filter(
                (location) =>
                    !parentNode.children.find((item) => item.name === location.fullName || item.name === location.name),
            );

            const locationNodesToRemove = parentNode.children.filter(
                (location) =>
                    !this.selectedItemLocations.find(
                        (item) => item.fullName === location.name || item.name === location.name,
                    ) &&
                    this.locationOptions.find((item) => item.fullName === location.name || item.name === location.name),
            );

            const parentIndex = this.tasks.findIndex((item) => item.id === parentNode.id);
            Promise.all([
                ...locationNodesToCreate.map(async (loc) => {
                    const newTask = await planning2Service.createTasks(
                        this.$route.params.projectId,
                        this.selectedPlanningId,
                        {
                            bundleId: this.selectedItem.type === 'bundle' ? this.selectedItem.id : parentNode.bundleId,
                            parentId: this.selectedItem.type === 'bundle' ? null : parentNode.id,
                            locationId: loc._isStringCriteria ? null : loc.id,
                            name: loc.fullName || loc.name,
                            type: 'location',
                        },
                    );
                    const newNode = {
                        ...newTask,
                        level: parentNode.level + 1,
                        editable: true,
                        children: [],
                        locationId: loc._isStringCriteria ? null : loc.id,
                    };
                    parentNode.children.push(newNode);
                    this.tasks.splice(
                        parentIndex + 1 + parentNode.children.findIndex((child) => child.name === newNode.name),
                        0,
                        newNode,
                    );
                }),
                ...locationNodesToRemove.map(async (loc) => {
                    await planning2Service.deleteTasks(this.$route.params.projectId, this.selectedPlanningId, loc.id);
                    const nodeIndex = this.tasks.indexOf(loc);
                    this.tasks.splice(nodeIndex, 1);
                    parentNode.children = parentNode.children.filter((node) => node.id !== loc.id);
                }),
            ]);
        },
        onClick() {
            if (this.contextMenuOpen) {
                this.$refs.contextMenu.close();
                this.contextMenuOpen = false;
            }
        },
        async createPlanning(planning) {
            const newPlanning = await planning2Service.createPlanning(this.$route.params.projectId, planning);
            this.plannings.unshift(newPlanning);
            this.selectedPlanningId = newPlanning.id;
            await this.loadTasks();
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        async loadTasks() {
            if (this.selectedPlanningId) {
                this.taskLoading = true;
                this.DBTasks = await planning2Service.getTasks(this.$route.params.projectId, this.selectedPlanningId);
                this.taskLoading = false;
            }
        },
        async onPlanningSelect(id) {
            if (id === 'importMsProject') {
                this.$refs.filePickerMsProject.click();
            } else if (id === 'importGanttProject') {
                this.$refs.filePickerGanttProject.click();
            } else if (id === 'new') {
                this.$refs.newPlanning.open();
                this.selectedPlanningId = null;
            } else {
                await this.loadTasks();
                this.tasks = mapTasks2(this.buildTaskTree());
            }
        },
        async onPickMSProjectFile(event) {
            const planning = await planning2Service.createPlanning(this.$route.params.projectId, {
                name: event.target.files[0].name,
            });
            this.plannings.unshift(planning);
            this.selectedPlanningId = planning.id;
            await planning2Service.importXML(this.$route.params.projectId, planning.id, event.target.files[0]);
            await this.loadTasks();
            this.structure = 'free';
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        async onPickGanttProjectFile(event) {
            const planning = await planning2Service.createPlanning(this.$route.params.projectId, {
                name: event.target.files[0].name,
            });
            this.plannings.unshift(planning);
            this.selectedPlanningId = planning.id;
            await planning2Service.importCSV(this.$route.params.projectId, planning.id, event.target.files[0]);
            await this.loadTasks();
            this.structure = 'free';
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        updateTaskStartDate({ id, startDate }) {
            planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id,
                plannedStartDate: startDate,
            });
        },
        updateTaskRealEndDate({ id, realStartDate, realEndDate }) {
            planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id,
                realEndDate,
                realProgress: 100,
                realStartDate: realStartDate || realEndDate,
            });
        },
        updateTaskEndDate({ id, endDate }) {
            planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id,
                plannedEndDate: endDate,
            });
        },
        updateTaskRealStartDate({ id, realStartDate }) {
            planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, { id, realStartDate });
        },
        updateTaskDuration({ id, duration }) {
            planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                id,
                plannedDuration: duration,
            });
        },
        updateTaskProgress({ id, realStartDate, realEndDate, progress }) {
            if ((progress > 0 && progress < 100 && realStartDate) || progress === 0) {
                planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                    id,
                    realProgress: progress,
                });
            } else if (progress > 0 && progress < 100 && !realStartDate) {
                planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                    id,
                    realProgress: progress,
                    realStartDate: startOfDay(new Date()),
                });
            } else if (progress >= 100 && !realEndDate) {
                planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                    id,
                    realProgress: 100,
                    realEndDate: startOfDay(new Date()),
                });
            } else if (progress >= 100) {
                planning2Service.updateTasks(this.$route.params.projectId, this.selectedPlanningId, {
                    id,
                    realProgress: 100,
                });
            }
        },
        selectNextTask(event) {
            if (event.target.nodeName === 'BODY') {
                const currentIndex = this.filteredLines.indexOf(
                    this.filteredLines.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const nextTask = this.filteredLines.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (nextTask) {
                        this.onSelect(nextTask);
                        event.preventDefault();
                        this.strollTo(nextTask);
                    }
                }
            }
        },
        selectPreviousTask(event) {
            if (event.target.nodeName === 'BODY') {
                const reversedTasks = [...this.filteredLines].reverse();
                const currentIndex = reversedTasks.indexOf(
                    reversedTasks.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const previousTask = reversedTasks.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (previousTask) {
                        this.onSelect(previousTask);
                        event.preventDefault();
                        this.strollTo(previousTask);
                    }
                }
            }
        },
        strollTo(task) {
            const element = document.querySelector('#uuid_' + task.id);
            if (element && !isInViewport(element)) {
                element.scrollIntoView();
            }
        },
        collapse: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: true };
            this.updateState();
        },
        collapseAll: function () {
            for (const line of this.lines) {
                this.collapsedLines[line.id] = true;
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandToLevel: function (level) {
            for (const line of this.lines) {
                if (line.level < level) {
                    this.collapsedLines[line.id] = false;
                } else {
                    this.collapsedLines[line.id] = true;
                }
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = false;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        collapseDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = true;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandAll: function () {
            this.collapsedLines = {};
            this.updateState();
        },
        expand: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: false };
            this.updateState();
        },
        async navigateToDate(date) {
            this.options.minDate = date;
            await this.updateState();
        },
        updated: function () {
            if (this.$refs.main && this.$refs.planningToolbar) {
                this.ganttMaxHeight =
                    this.$refs.main.offsetHeight -
                    this.$refs.planningToolbar.offsetHeight -
                    (this.$refs.details ? this.$refs.details.offsetHeight : 0) +
                    'px';
                this.$refs.gantt?.onResize();
            }
        },
        onSelect(item) {
            this.selectedItem = item;
            if (item.type === 'location') {
                const parent =
                    this.tasks.find((task) => task.id === this.selectedItem.parentId) ||
                    this.tasks.find((task) => task.id === this.selectedItem.bundleId);
                this.selectedItemLocations = this.locationOptions.filter((location) =>
                    parent.children.find((item) => item.name === location.fullName),
                );
            } else if (item.children) {
                this.selectedItemLocations = this.locationOptions.filter((location) =>
                    item.children.find((item) => item.name === location.fullName),
                );
            }
            this.updateState();
        },
        onOpen(item) {
            if (!item.children || item.children.length === 0) {
                this.$refs.taskPopup.open();
            }
        },
        onContextMenu({ event, item }) {
            event.preventDefault();
            event.stopPropagation();
            this.onSelect(item);
            const menu = this.$refs.contextMenu.$refs.menu;
            menu.open();
            menu.setPosition(event.pageX, event.pageY, 'right');
            this.contextMenuOpen = true;
            this.updateState();
        },
        today() {
            this.options.minDate = sub(startOfDay(new Date()), { [this.options.scale]: 8 });
            this.updateState();
        },
        next() {
            if (this.options.scale === 'days') {
                this.options.minDate = add(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = add(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = add(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        previous() {
            if (this.options.scale === 'days') {
                this.options.minDate = sub(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = sub(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = sub(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        expandTo(level) {
            if (level === 'all') {
                this.expandAll();
            } else {
                this.expandToLevel(parseInt(level));
            }
        },
    },
};
</script>
