import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const deliveryContacts = {
    schema: {
        title: 'deliveryContact schema',
        version: 3,
        description: 'describes a deliveryContact',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'] },
            companyName: { type: ['string', 'null'] },
            firstName: { type: ['string', 'null'] },
            lastName: { type: ['string', 'null'] },
            email: { type: ['string', 'null'] },
            phone: { type: ['string', 'null'] },
            type: { type: ['string', 'null'] },
            isOccupant: { type: ['boolean', 'null'] },
            isArchived: { type: ['boolean', 'null'] },
            hasTexAccess: { type: ['boolean', 'null'] },
            locationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            createdBy: { type: ['string', 'null'] },
            updatedAt: { type: ['string', 'null'] },
            deletedAt: { type: ['string', 'null'] },
            deletedBy: { type: ['string', 'null'] },
            createdAt: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },
            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => {
            delete doc.isActive;
            doc.isArchived = false;
            doc.hasAtexAccess = false;
            return doc;
        },
        3: (doc) => {
            doc.hasTexAccess = doc.hasAtexAccess;
            delete doc.hasAtexAccess;
            return doc;
        },
    },
};
