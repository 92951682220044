<template>
    <ValidationObserver v-slot="{ errors, invalid, touched }" class="h-full block">
        <form class="flex flex-col">
            <div class="my-2 flex justify-start w-full">
                <app-multi-picker
                    :value="selectedPredecessors"
                    :options="predecessorOptions"
                    size="mini"
                    variant="button"
                    @check="onCheckPredecessor"
                    @uncheck="onUncheckPredecessor"
                    :show-input="false"
                    :show-label="false"
                    :label="$t('project.follow.planning.newPredecessor')"
                    icon="icon-plus-box-outline"
                    label-key="path"
                    class="font-normal"
                    :disableToolTips="$t('project.follow.planning.isSuccessor')"
                ></app-multi-picker>
            </div>
            <table class="flex-grow text-xs table-fixed" v-if="task.predecessors.length > 0">
                <thead>
                    <tr class="border-b">
                        <th
                            scope="col"
                            class="pb-1 whitespace-nowrap bg-white"
                            :style="{
                                width: '92px',
                            }"
                        >
                            {{ $t('project.follow.planning.predecessorType') }}
                        </th>
                        <th
                            scope="col"
                            class="pb-1 bg-white"
                            :style="{
                                width: '72px',
                            }"
                        >
                            {{ $t('project.follow.planning.delay') }}
                        </th>
                        <th scope="col" class="pb-1 whitespace-nowrap bg-white">
                            <span>
                                {{ $t('project.follow.planning.predecessors') }} ({{ task.predecessors.length }})
                            </span>
                        </th>
                        <th
                            scope="col"
                            class="pb-1 top-0 bg-white"
                            :style="{
                                width: '70px',
                            }"
                        >
                            <span class="hidden">tools column</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="4">
                            <div class="flex justify-center">
                                <app-tips class="my-2">
                                    {{ $t('project.follow.planning.emptyPredecessors') }}
                                </app-tips>
                            </div>
                        </td>
                    </tr>
                    <template v-for="predecessor in taskPredecessors">
                        <tr class="border-b">
                            <td class="px-4">
                                <app-drop-down-button
                                    :label="predecessor.typeLabel"
                                    :show-label="false"
                                    :icon="'icon-gantt' + predecessor.type.toLowerCase()"
                                    :title="$t('services.predecessorType' + predecessor.type)"
                                    @input="changePredecessorType(predecessor)"
                                    v-model="predecessor.type"
                                >
                                    <option value="ES">
                                        {{ $t('services.predecessorTypeES') }}
                                    </option>
                                    <option value="SS">
                                        {{ $t('services.predecessorTypeSS') }}
                                    </option>
                                    <option value="EE">
                                        {{ $t('services.predecessorTypeEE') }}
                                    </option>
                                </app-drop-down-button>
                            </td>
                            <td class="px-4">
                                <app-number-link
                                    v-model="predecessor.delay"
                                    format="integer"
                                    :positiveOnly="false"
                                    @input="updatePredecessor(predecessor)"
                                ></app-number-link>
                            </td>
                            <td class="px-2">
                                <div class="flex" v-if="predecessor.name">
                                    <span class="flex gap-1" :class="{ 'text-red-700': predecessor.isError }">
                                        {{ predecessor.name }}
                                        <span
                                            v-if="successors.includes(predecessor.taskId)"
                                            :title="$t('project.follow.planning.error.predecessor-loop')"
                                        >
                                            <icon-all-inclusive width="16" height="16" />
                                        </span>
                                    </span>
                                    <span class="flex-grow" />
                                    <span class="flex gap-1 text-xs whitespace-no-wrap">
                                        {{ $t('commons.from') }}
                                        {{ predecessor.startDate | humanizeDate }}
                                        {{ $t('commons.to') }}
                                        {{ predecessor.estimatedEndDate | humanizeDate }}
                                    </span>
                                </div>
                                <div class="text-red-700 text-xs font-bold flex" v-else>
                                    <icon-link-variant-off width="16" height="16" class="mr-2" />
                                    {{ $t('project.follow.planning.deletedPredecessor') }}
                                </div>
                            </td>
                            <td class="p-1 px-2 flex items-center justify-end mr-1">
                                <app-button
                                    size="mini"
                                    @click.prevent="removePredecessor(predecessor)"
                                    icon="icon-trash-can-outline"
                                    variant="danger"
                                    aria-label="delete predecessor"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </form>
    </ValidationObserver>
</template>

<script>
import AppSelect from '../../../components/appSelect/AppSelect';
import { confirm } from '../../dialogs/dialogs.service';
import AppButton from '../../../components/appButton/AppButton';
import AppLabel from '../../../components/appLabel/AppLabel';
import IconLinkVariantOff from '@/icons/IconLinkVariantOff';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import tasksService from '@/features/tasks/plannedTasks.service';
import AppTips from '@/components/app-tips/AppTips';
import { sortBy } from '@/services/sanitize.service';
import { addTaskPredecessors, removeTaskPredecessor, updateTaskPredecessor } from '@/features/tasks/tasks.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
export default {
    components: {
        AppDropDownButton,
        AppNumberLink,
        AppTips,
        AppMultiPicker,
        IconLinkVariantOff,
        AppLabel,
        AppButton,
        AppSelect,
    },
    props: {
        task: Object,
        tasks: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        successors() {
            return tasksService.getSuccessorIds2(this.task, this.tasks);
        },
        taskPredecessors() {
            return this.task.predecessors.map((predecessor) => {
                const task = this.tasks.find((task) => task.id === predecessor.taskId);
                if (task) {
                    return {
                        ...predecessor,
                        name: task.location?.fullName + ' > ' + task.bundle?.reference + ' > ' + task.name,
                        startDate: task.realStartDate || task.startDate,
                        estimatedEndDate: task.estimatedEndDate,
                        isError: task.isError,
                    };
                } else {
                    return predecessor;
                }
            });
        },
        selectedPredecessors() {
            return this.task.predecessors.map(({ taskId }) => ({
                id: taskId,
            }));
        },
        predecessorOptions() {
            const result = this.tasks
                .filter((task) => this.task.id !== task.id)
                .map((task) => ({
                    ...task,
                    disabled: this.successors.includes(task.id),
                    path: task.location?.fullName + ' > ' + task.bundle?.reference + ' > ' + task.name,
                }));
            return sortBy(result, (result) => result.path.replace('+', 'z+'));
        },
    },
    data() {
        return {
            newPredecessor: {
                type: 'ES',
                taskId: null,
                delay: 0,
            },
        };
    },
    methods: {
        changePredecessorType(predecessor) {
            updateTaskPredecessor(this.$route.params.projectId, this.task.id, {
                type: predecessor.type,
                taskId: predecessor.taskId,
            });
        },
        onCheckPredecessor(tasks) {
            return addTaskPredecessors(
                this.$route.params.projectId,
                this.task.id,
                tasks.map((task) => ({
                    taskId: task.id,
                    delay: 0,
                    type: 'ES',
                })),
            );
        },
        onUncheckPredecessor(tasks) {
            return tasks.map((task) => removeTaskPredecessor(this.$route.params.projectId, this.task.id, task.id));
        },
        async removePredecessor(predecessor) {
            if (await confirm(this.$t('project.follow.planning.confirmPredecessorMessage'))) {
                return removeTaskPredecessor(this.$route.params.projectId, this.task.id, predecessor.taskId);
            }
        },
        async updatePredecessor(predecessor) {
            return updateTaskPredecessor(this.$route.params.projectId, this.task.id, {
                taskId: predecessor.taskId,
                delay: predecessor.delay || 0,
                type: predecessor.type,
            });
        },
    },
};
</script>
<style scoped>
.threeLinesMax {
    max-height: 10rem;
    overflow-y: scroll;
}
</style>
