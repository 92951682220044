<template>
    <div class="flex">
        <router-view></router-view>
    </div>
</template>

<script>
import { error } from '../toasts/toats.service';
import { getProject } from './projects.service';
import { updateBreadCrumbs } from '@/state/state';

export default {
    created() {
        this.subscriptions = [
            getProject(this.$route.params.projectId).subscribe((project) => {
                if (!project) {
                    error(this.$t('project.information.generals.unknown'));
                    this.$router.push({ name: 'home' });
                } else {
                    updateBreadCrumbs({ projectName: project.name });
                    if (this.$route.name && !this.$route.name.includes('print')) {
                        document.title = 'Tex - ' + (project.code ? project.code + ' ' : '') + project.name;
                    } else {
                        document.title =
                            (project.code ? project.code + ' ' : '') +
                            project.name +
                            (this.$route.query.titleSuffix ? ' - ' + this.$route.query.titleSuffix : '');
                    }
                }
            }),
        ];
    },
    data() {
        return {
            subscriptions: [],
        };
    },
};
</script>
