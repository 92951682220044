import { getMapById } from '@/services/sanitize.service';
import { getEndDate } from '@/features/tasks/plannedTasks.service';
import db from '@/rxdb/database';
import { map } from 'rxjs';
import { humanizeDate } from '@/filters/dateFilter';
import { sortTasks } from '@/features/tasks/tasks.service';
import add from 'date-fns/add';

export async function updateMeeting(projectId, meeting) {
    const dbMeeting = await db
        .getProjectCollections(projectId)
        .meetings.findOne({ selector: { id: meeting.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(meeting)) };
    if (meeting.date === null || !!meeting.date) {
        patch.date = meeting.date ? meeting.date.toISOString() : null;
    }
    if (meeting.planningStartDate === null || !!meeting.planningStartDate) {
        patch.planningStartDate = meeting.planningStartDate ? meeting.planningStartDate.toISOString() : null;
    }
    if (meeting.planningEndDate === null || !!meeting.planningEndDate) {
        patch.planningEndDate = meeting.planningEndDate ? meeting.planningEndDate.toISOString() : null;
    }
    if (meeting.bundleDataStartDate === null || !!meeting.bundleDataStartDate) {
        patch.bundleDataStartDate = meeting.bundleDataStartDate ? meeting.bundleDataStartDate.toISOString() : null;
    }
    if (meeting.bundleDataEndDate === null || !!meeting.bundleDataEndDate) {
        patch.bundleDataEndDate = meeting.bundleDataEndDate ? meeting.bundleDataEndDate.toISOString() : null;
    }
    if (meeting.bundleTasksStartDate === null || !!meeting.bundleTasksStartDate) {
        patch.bundleTasksStartDate = meeting.bundleTasksStartDate ? meeting.bundleTasksStartDate.toISOString() : null;
    }
    if (meeting.bundleTasksEndDate === null || !!meeting.bundleTasksEndDate) {
        patch.bundleTasksEndDate = meeting.bundleTasksEndDate ? meeting.bundleTasksEndDate.toISOString() : null;
    }
    return dbMeeting.atomicPatch(patch);
}

export function initNewMeeting(project, agenda, existingMeetings, translateFn) {
    if (project.me.allowedFeatures.includes('project_meetingReportsMOE')) {
        return initNewMOEMeeting(
            project.endDate,
            agenda,
            existingMeetings.filter((meeting) => meeting.type === 'MOE'),
            translateFn,
        );
    } else if (project.me.allowedFeatures.includes('project_meetingReportsMOA')) {
        return initNewMOAMeeting(
            project.endDate,
            agenda,
            existingMeetings.filter((meeting) => meeting.type === 'MOA'),
            translateFn,
        );
    }
}
async function initNewMOAMeeting(projectEndDate, agenda, existingMeetings, translateFn) {
    return {
        ...initNewMOEMeeting(projectEndDate, agenda, existingMeetings, translateFn),
        type: 'MOA',
        showTasksTable: false,
        showPlanning: false,
        showGeneralObservations: false,
        showPreparations: false,
        showPreparationVisas: false,
        showTasksSectionBundlePage: false,
        showReceptions: false,
        showAutoControls: false,
        showConceptionVisas: false,
        showConceptions: false,
    };
}
function initNewMOEMeeting(projectEndDate, agenda, existingMeetings, translateFn) {
    const lastMeeting = existingMeetings[existingMeetings.length - 1];
    const lastMeetingReportCover = lastMeeting
        ? lastMeeting.showCoverPageWithPhoto && existingMeetings.filter((meeting) => meeting.coverPhotoUrl).pop()
        : null;
    const nextDate = lastMeeting ? add(lastMeeting.date, { days: 7 }) : new Date();
    const nextNextDate = add(nextDate, { days: 7 });
    return {
        name: translateFn('project.follow.meetings.newName') + ' ' + humanizeDate(nextDate),
        type: 'MOE',
        location: translateFn('project.follow.meetings.onSite'),
        date: nextDate,
        bundleDataStartDate: lastMeeting ? lastMeeting.date : nextDate,
        bundleDataEndDate: projectEndDate || nextNextDate,
        bundleTasksStartDate: lastMeeting ? lastMeeting.date : nextDate,
        bundleTasksEndDate: projectEndDate || nextNextDate,
        coverPhotoUrl: lastMeetingReportCover ? lastMeetingReportCover.coverPhotoUrl : null,
        showCoverPageWithPhoto: !!lastMeetingReportCover,
        emitterIds: lastMeeting ? lastMeeting.emitterIds : [],
        ...(lastMeeting
            ? {
                  showEmptyBundlePage: lastMeeting.showEmptyBundlePage,
                  showEmptySectionBundlePage: lastMeeting.showEmptySectionBundlePage,
                  showParticipantsIndex: lastMeeting.showParticipantsIndex,
                  showGeneralProgress: lastMeeting.showGeneralProgress,
                  showTasksTable: lastMeeting.showTasksTable,
                  showPlanning: lastMeeting.showPlanning,
                  showGeneralObservations: lastMeeting.showGeneralObservations,
                  showPreparations: lastMeeting.showPreparations,
                  showPreparationVisas: lastMeeting.showPreparationVisas,
                  showTasksSectionBundlePage: lastMeeting.showTasksSectionBundlePage,
                  showCertificates: lastMeeting.showCertificates,
                  showReceptions: lastMeeting.showReceptions,
                  showAutoControls: lastMeeting.showAutoControls,
                  showEXEObservations: lastMeeting.showEXEObservations,
                  showOPRObservations: lastMeeting.showOPRObservations,
                  showReceiptObservations: lastMeeting.showReceiptObservations,
                  showOPLObservations: lastMeeting.showOPLObservations,
                  showDeliveryObservations: lastMeeting.showDeliveryObservations,
                  showAPAObservations: lastMeeting.showAPAObservations,
                  showConceptions: lastMeeting.showConceptions,
                  showConceptionVisas: lastMeeting.showConceptionVisas,
                  planningStructure: lastMeeting.planningStructure,
                  planningScale: lastMeeting.planningScale,
                  planningShowProgressLine: lastMeeting.planningShowProgressLine,
                  convocations: lastMeeting.convocations.map((convocation) => ({
                      bundleId: convocation.bundleId,
                      convocationDate: nextDate,
                      attendance: 'present',
                  })),
              }
            : {}),
    };
}
function newMeetingMap(meeting) {
    return {
        ...JSON.parse(JSON.stringify(meeting)),
        planningShowProgressLine: false,
        recipientIds: meeting.recipientIds ? JSON.parse(JSON.stringify(meeting.recipientIds)) : [],
        convocations: meeting.convocations ? JSON.parse(JSON.stringify(meeting.convocations)) : [],
        attachments: meeting.attachments ? JSON.parse(JSON.stringify(meeting.attachments)) : [],
        date: meeting.date ? meeting.date.toISOString() : new Date().toISOString(),
        planningStartDate: meeting.planningStartDate ? meeting.planningStartDate.toISOString() : null,
        planningEndDate: meeting.planningEndDate ? meeting.planningEndDate.toISOString() : null,
        bundleDataStartDate: meeting.bundleDataStartDate ? meeting.bundleDataStartDate.toISOString() : null,
        bundleDataEndDate: meeting.bundleDataEndDate ? meeting.bundleDataEndDate.toISOString() : null,
        bundleTasksStartDate: meeting.bundleTasksStartDate ? meeting.bundleTasksStartDate.toISOString() : null,
        bundleTasksEndDate: meeting.bundleTasksEndDate ? meeting.bundleTasksEndDate.toISOString() : null,
    };
}
export async function createMeeting(projectId, meeting) {
    const result = await db.getProjectCollections(projectId).meetings.insert(newMeetingMap(meeting));
    return result.toJSON();
}
export function createMeetings(projectId, meetings) {
    return db.getProjectCollections(projectId).meetings.bulkInsert(meetings.map(newMeetingMap));
}

function mapMeeting(meetingDb) {
    return meetingDb
        ? {
              ...meetingDb.toMutableJSON(),
              date: meetingDb.date ? new Date(meetingDb.date) : null,
              planningStartDate: meetingDb.planningStartDate ? new Date(meetingDb.planningStartDate) : null,
              planningEndDate: meetingDb.planningEndDate ? new Date(meetingDb.planningEndDate) : null,
              bundleDataStartDate: meetingDb.bundleDataStartDate ? new Date(meetingDb.bundleDataStartDate) : null,
              bundleDataEndDate: meetingDb.bundleDataEndDate ? new Date(meetingDb.bundleDataEndDate) : null,
              bundleTasksStartDate: meetingDb.bundleTasksStartDate ? new Date(meetingDb.bundleTasksStartDate) : null,
              bundleTasksEndDate: meetingDb.bundleTasksEndDate ? new Date(meetingDb.bundleTasksEndDate) : null,
          }
        : null;
}
export function getMeetings(projectId) {
    return db
        .getProjectCollections(projectId)
        .meetings.find({ selector: {}, sort: [{ date: 'asc' }] })
        .$.pipe(map((items) => items.map(mapMeeting)));
}

export function getMeeting(projectId, meetingId) {
    return db
        .getProjectCollections(projectId)
        .meetings.findOne({ selector: { id: meetingId } })
        .$.pipe(map(mapMeeting));
}
export async function queryMeeting(projectId, meetingId) {
    const dbMeeting = await db
        .getProjectCollections(projectId)
        .meetings.findOne({
            selector: { id: meetingId },
        })
        .exec();
    return mapMeeting(dbMeeting);
}

export async function removeMeeting(projectId, meetingId) {
    const entity = await db
        .getProjectCollections(projectId)
        .meetings.findOne({ selector: { id: meetingId } })
        .exec();
    return entity.remove();
}
function buildDirectoryNamePath(directories) {
    const directoryMap = getMapById(directories);
    const resultDirectoryPathById = {};

    for (let directory of directories) {
        let name = directory.name;
        while (directory.parentDirectoryId) {
            const parent = directoryMap[directory.parentDirectoryId];
            name = parent.name + ' > ' + name;
            directory = parent;
        }
        resultDirectoryPathById[directory.id] = name;
    }
    return resultDirectoryPathById;
}
function getDateKeyToSortBy(task, agenda, meetingDate) {
    if (!task.realStartDate) {
        return task.startDate;
    } else {
        return getEndDate(task, agenda, meetingDate);
    }
}
export function buildTree(folders, bundles, directories, tasks, agenda, meetingDate, sortKey) {
    const result = [];
    for (const folder of folders) {
        for (const location of folder.locations) {
            const locationNode = { folder, location, children: [], leafCount: 0 };
            if (tasks.find((task) => task.locationId === location.id)) {
                result.push(locationNode);
                for (const bundle of bundles) {
                    const directoryName = buildDirectoryNamePath(
                        directories.filter((directory) => directory.bundleId === bundle.id),
                    );
                    const bundleNode = { bundle: bundle, name: bundle.label, children: [] };
                    if (tasks.find((task) => task.locationId === location.id && task.service.bundleId === bundle.id)) {
                        locationNode.children.push(bundleNode);
                        for (const task of tasks.filter(
                            (task) => task.locationId === location.id && task.service.bundleId === bundle.id,
                        )) {
                            const taskDate = getDateKeyToSortBy(task, agenda, meetingDate);
                            if (!locationNode.date || locationNode.date > taskDate) {
                                locationNode.date = taskDate;
                            }
                            if (!bundleNode.date || bundleNode.date > taskDate) {
                                bundleNode.date = taskDate;
                            }
                            locationNode.leafCount++;
                            bundleNode.children.push({
                                ...task,
                                date: taskDate,
                                name: task.service.name,
                                path: task.service.parentDirectoryId
                                    ? directoryName[task.service.parentDirectoryId]
                                    : '',
                            });
                        }
                    }
                }
            }
        }
    }
    for (const location of result) {
        location.children = sortTasks(location.children, sortKey);
        for (const bundleNode of location.children) {
            bundleNode.children = sortTasks(bundleNode.children, sortKey);
        }
    }
    return sortTasks(result, sortKey);
}
export default { updateMeeting };
