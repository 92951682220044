import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const preparations = {
    schema: {
        title: 'preparation schema',
        version: 2,
        description: 'describes a preparation',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: { type: 'string', final: true, maxLength: 40 },
            name: { type: ['string', 'null'] },
            groupId: { type: ['string', 'null'] },
            bundleId: { type: ['string', 'null'] },
            type: { type: ['string', 'null'] },
            code: { type: ['string', 'null'] },
            phase: { type: ['string', 'null'] },
            subPhase: { type: ['string', 'null'] },
            index: { type: ['number', 'string', 'null'] },
            deliveryDuration: { type: ['number', 'null'] },
            emissionDueDate: { type: ['string', 'null'] },
            emissionDate: { type: ['string', 'null'] },
            locationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            predecessors: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        type: { type: 'string' },
                        delay: { type: 'number' },
                        taskId: { type: 'string' },
                    },
                },
            },
            createdBy: { type: ['string', 'null'], final: true },
            updatedAt: { type: ['string', 'null'] },
            deletedAt: { type: ['string', 'null'] },
            deletedBy: { type: ['string', 'null'] },
            createdAt: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },
            projectId: { type: ['string', 'null'] },
            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => ({ ...doc, predecessors: [] }),
        2: (doc) => doc,
    },
};
