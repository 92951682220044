var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"role":"navigation"}},[_c('ul',{staticClass:"border-r text-black app-menu max-h-main min-h-main overflow-y-auto flex-col flex pb-10 md:pb-0"},[_vm._t("beforeItems"),_vm._l((_vm.menuItems),function(item){return _c('li',{key:item.title,staticClass:"flex flex-col",class:{
                'selected-group': _vm.$route.matched.some((route) => _vm.startWithPath(route.meta.menu, item.menu)),
                'pb-2': item.items.length > 0,
            }},[_c('a',{staticClass:"pl-3 flex items-center cursor-pointer",attrs:{"href":"#"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.navigateToGroup(item)},"click":function($event){return _vm.navigateToGroup(item)}}},[(item.icon)?_c('icon',{attrs:{"name":item.icon,"width":"16px"}}):_vm._e(),_c('div',{staticClass:"p-2",class:{
                        'font-bold': _vm.$route.matched.some((route) => _vm.startWithPath(route.meta.menu, item.menu)),
                    }},[_vm._v("\n                    "+_vm._s(_vm.$t(item.title))+"\n                ")])],1),(_vm.$route.matched.some((route) => _vm.startWithPath(route.meta.menu, item.menu)))?_c('ul',_vm._l((item.items),function(subItem){return _c('li',{key:subItem.title,staticClass:"text-sm cursor-pointer flex pl-2",class:{
                        'font-bold selected-item': _vm.$route.matched.some((route) =>
                            _vm.startWithPath(route.meta.menu, subItem.menu),
                        ),
                    },attrs:{"role":"menuitem"}},[_c('router-link',{staticClass:"pl-6 p-2 w-full",attrs:{"to":{ name: subItem.route }},nativeOn:{"click":function($event){return _vm.navigate(item)}}},[_vm._v("\n                        "+_vm._s(_vm.$t(subItem.title))+"\n                    ")])],1)}),0):_vm._e()])}),_c('li',{staticClass:"flex flex-col m-4 p-1 text-xs justify-center items-center"},[_c('a',{staticClass:"flex items-center justify-center underline pointer p-1",attrs:{"href":""},on:{"click":function($event){return _vm.$router.push({ name: 'help' })}}},[_c('icon-lifebuoy',{staticClass:"inline",attrs:{"width":"16px"}}),_c('span',{staticClass:"p-2"},[_vm._v(_vm._s(_vm.$t('menu.needHelp')))])],1)]),_c('li',{staticClass:"flex flex-col items-center text-center justify-end flex-grow text-xs gap-1 text-gray-700"},[_c('app-upgrade-button',{attrs:{"show-icon":true}},[_c('span',{},[_vm._v("v3.0.PATCH_VERSION")])]),_c('span',{staticClass:"my-2 text-sm"},[_vm._v("© 2020-2025 Tex")])],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }