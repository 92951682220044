import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const planningExports = {
    schema: {
        title: 'planningExports schema',
        version: 5,
        description: 'describes a planningExports',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: { type: 'string', final: true, maxLength: 40 },
            fileName: { type: ['string', 'null'] },
            name: { type: ['string', 'null'] },
            url: { type: ['string', 'null'] },
            path: { type: ['string', 'null'] },
            generationEndDate: { type: ['string', 'null'] },
            generationStartDate: { type: ['string', 'null'] },
            generationSucceeded: { type: ['boolean', 'null'] },
            refDate: { type: ['string', 'null'], format: 'date-time' },
            minDate: { type: ['string', 'null'] },
            maxDate: { type: ['string', 'null'] },
            format: { type: ['string', 'null'] },
            landscape: { type: ['boolean', 'null'] },
            scale: { type: ['string', 'null'] },
            structure: { type: ['string', 'null'] },
            expandToLevel: { type: ['number', 'null'] },
            showProgressLine: { type: ['boolean', 'null'] },
            showReferenceData: { type: ['boolean', 'null'] },
            showPlannedData: { type: ['boolean', 'null'] },
            showReference: { type: ['boolean', 'null'] },
            progressReportedTo: { type: ['string', 'null'] },
            showReal: { type: ['boolean', 'null'] },
            showPlanned: { type: ['boolean', 'null'] },
            showLateColumn: { type: ['boolean', 'null'] },
            showQuantityColumn: { type: ['boolean', 'null'] },
            showDurationColumn: { type: ['boolean', 'null'] },
            showProgressColumn: { type: ['boolean', 'null'] },
            showRealData: { type: ['boolean', 'null'] },
            showOutOfRangeLines: { type: ['boolean', 'null'] },
            sortKey: { type: ['string', 'null'] },
            footnote: { type: ['string', 'null'] },
            filter: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                },
            },
            collapsed: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            createdBy: {
                type: ['string', 'null'],
                final: true,
            },
            updatedAt: {
                type: ['string', 'null'],
            },
            deletedAt: {
                type: ['string', 'null'],
            },
            deletedBy: {
                type: ['string', 'null'],
            },
            createdAt: {
                type: ['string', 'null'],
            },
            updatedBy: {
                type: ['string', 'null'],
            },
            projectId: {
                type: 'string',
            },
            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => ({ ...doc, landscape: doc.landscape === 'true' }),
        2: (doc) => ({ ...doc, path: null }),
        3: (doc) => ({ ...doc, sortKey: 'date' }),
        4: (doc) => ({ ...doc }),
        5: (doc) => ({ ...doc, footnote: doc.footnote || '' }),
    },
};
