<template>
    <main class="p-2 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addPreparation()" :label="$t('preparations.newPreparation')" v-if="!readOnly" />
                <div class="hidden sm:flex mx-2 flex-grow items-center text-xs" v-if="!readOnly">
                    <form @submit.prevent="">
                        <app-number-link
                            v-model="preparationReviewMargin"
                            :label="$t('preparations.preparationReviewMargin') + ' :'"
                            class="col-span-2"
                            @input="saveConfig"
                        >
                            <span class="underline">
                                {{ (preparationReviewMargin || 0) + 'jo' }}
                            </span>
                        </app-number-link>
                    </form>
                </div>
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.fullName || option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row sm:">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        :label="$t('project.follow.follow.done') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="far"
                        :label="$t('project.follow.follow.far') + ' (' + farCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center hidden sm:flex"
                        v-model="onlyLastVersion"
                        :label="$t('project.follow.follow.lastVersionOnly')"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="hidden sm:table-cell text-left border-r p-1" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 5rem" class="hidden sm:table-cell text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('type')"
                            >
                                {{ $t('preparations.type') }}
                                <div v-if="sortKey === 'type'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                {{ $t('preparations.name') }}
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1" style="width: 15rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emitter')"
                            >
                                {{ $t('bundles.emitter') }}
                                <div v-if="sortKey === 'emitter'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div :title="$t('commons.version')">
                                {{ $t('commons.versionShort') }}
                            </div>
                        </th>
                        <th class="text-center border-r p-1" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDueDate')"
                            >
                                {{ $t('preparations.emissionDueDateShort') }}
                                <div v-if="sortKey === 'emissionDueDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="hidden sm:table-cell text-center border-r p-1" style="width: 6rem">
                            {{ $t('preparations.emissionDateShort') }}
                        </th>
                        <th v-for="reviewer in reviewers" style="width: 3rem" class="hidden sm:table-cell border-r">
                            {{ $t('preparations.visas') }}
                            <div
                                :title="reviewer.name + (reviewer.company ? ' (' + reviewer.company.name + ')' : '')"
                                class="truncate"
                            >
                                {{ reviewer.reference || reviewer.name }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="group in filteredItems">
                        <template v-for="(preparation, index) in group.versions">
                            <tr
                                class="odd:bg-blue-50"
                                :class="{ 'border-t': onlyLastVersion }"
                                :style="
                                    !onlyLastVersion && index === 0
                                        ? 'border-top-color:#000;    border-top-width: 1px;'
                                        : 'border-top-width: 1px;'
                                "
                                v-if="!onlyLastVersion || index === 0"
                            >
                                <td class="hidden sm:table-cell border-r p-1" v-if="!readOnly">
                                    <a :id="'uuid_' + group.id" style="scroll-margin-top: 3em"></a>
                                    <app-checkbox
                                        :value="group.isSelected"
                                        :label="$t('commons.select')"
                                        :show-label="false"
                                        v-if="index === 0"
                                        @input="saveSelection(group)"
                                    ></app-checkbox>
                                </td>
                                <template v-if="index > 0">
                                    <td class="hidden sm:table-cell border-r p-1"></td>
                                    <td class="border-r p-1"></td>
                                    <td class="border-r p-1"></td>
                                </template>
                                <template v-else>
                                    <td class="hidden sm:table-cell border-r p-1">
                                        {{ preparation.type ? $t('preparations.types.' + preparation.type) : '' }}
                                    </td>
                                    <td class="border-r p-1">
                                        <router-link
                                            class="hover:underline"
                                            :to="{
                                                name: phase === 'exe' ? 'followPreparation' : 'conception',
                                                params: {
                                                    ...$route.params,
                                                    preparationId: preparation.id,
                                                },
                                            }"
                                        >
                                            <span v-if="preparation.code" class="mr-1">#{{ preparation.code }}</span>
                                            <span class="font-bold">{{ preparation.name }}</span>
                                            <span v-if="!preparation.name || preparation.name.trim().length === 0">
                                                ...
                                            </span>
                                        </router-link>
                                    </td>
                                    <td class="text-left border-r p-1">
                                        <div
                                            :title="
                                                preparation.bundle && preparation.bundle.company
                                                    ? preparation.bundle.company.name
                                                    : ''
                                            "
                                        >
                                            {{ preparation.bundle ? preparation.bundle.label : '' }}
                                        </div>
                                    </td>
                                </template>
                                <td class="hidden sm:table-cell text-center border-r p-1">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: phase === 'exe' ? 'followPreparation' : 'conception',
                                            params: {
                                                ...$route.params,
                                                preparationId: preparation.id,
                                            },
                                        }"
                                    >
                                        {{ group.versions.length - index }}
                                    </router-link>
                                </td>
                                <td
                                    class="text-center border-r p-1"
                                    :class="{
                                        'text-red-600 font-bold':
                                            !preparation.emissionDate && preparation.emissionDueDate < new Date(),
                                    }"
                                >
                                    <app-date-link
                                        @enter="focusToNextDueDate(preparation)"
                                        :ref="'dueDate_' + preparation.id"
                                        :disabled="readOnly"
                                        :label="$t('preparations.emissionDueDateShort')"
                                        :show-label="false"
                                        v-model="preparation.emissionDueDate"
                                        @input="updateDueDate(preparation, $event)"
                                    ></app-date-link>
                                </td>
                                <td class="hidden sm:table-cell text-center border-r p-1">
                                    <app-date-link
                                        @enter="focusToNextDate(preparation)"
                                        :ref="'date_' + preparation.id"
                                        :disabled="readOnly"
                                        :label="$t('preparations.emissionDateShort')"
                                        :show-label="false"
                                        v-model="preparation.emissionDate"
                                        @input="updateDate(preparation, $event)"
                                    ></app-date-link>
                                </td>
                                <td
                                    v-for="reviewer in reviewers"
                                    style="width: 3rem"
                                    class="hidden sm:table-cell border-r"
                                >
                                    <div
                                        class="flex justify-center"
                                        v-if="preparation.lastVisaByEmitterIdMap[reviewer.id]"
                                    >
                                        <router-link
                                            :to="{
                                                name: phase === 'exe' ? 'preparationVisa' : 'conceptionVisa',
                                                params: {
                                                    projectId: $route.params.projectId,
                                                    preparationId: preparation.id,
                                                    preparationVisaId: get(
                                                        preparation.lastVisaByEmitterIdMap[reviewer.id],
                                                        'id',
                                                    ),
                                                },
                                            }"
                                        >
                                            <app-preparation-visa-status
                                                :preparationEmissionDate="preparation.emissionDate"
                                                :visa="preparation.lastVisaByEmitterIdMap[reviewer.id]"
                                            ></app-preparation-visa-status>
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-select :label="$t('preparations.type')" v-model="editedPreparation.type">
                    <option value="note">{{ $t('preparations.types.note') }}</option>
                    <option value="plan">{{ $t('preparations.types.plan') }}</option>
                    <option value="technicalFile">{{ $t('preparations.types.technicalFile') }}</option>
                    <option value="technicalDetail">{{ $t('preparations.types.technicalDetail') }}</option>
                    <option value="sample">{{ $t('preparations.types.sample') }}</option>
                    <option value="prototype">{{ $t('preparations.types.prototype') }}</option>
                    <option value="synthesis">{{ $t('preparations.types.synthesis') }}</option>
                    <option value="other">{{ $t('preparations.types.other') }}</option>
                </app-select>
                <app-bundle-picker v-model="editedPreparation.bundle" :options="bundles"></app-bundle-picker>
                <app-date-input
                    v-model="editedPreparation.emissionDueDate"
                    :label="$t('preparations.emissionDueDate')"
                ></app-date-input>
                <div class="p-2">
                    <app-checkbox
                        v-model="editedPreparation.applyRecommended"
                        :label="$t('preparations.applyRecommended')"
                    ></app-checkbox>
                    <app-tips>
                        {{ $t('preparations.applyRecommendedTip') }}
                    </app-tips>
                </div>
                <app-date-input v-model="editedPreparation.emissionDate" :label="$t('preparations.emissionDate')" />
                <app-footer @click="saveMultiple" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="importPreparationsPopup" :showHeader="true" :title="$t('preparations.importPreparations')">
            <app-textarea
                ref="pasteArea"
                :placeholder="$t('preparations.importPreparationsTips')"
                @paste="onPaste"
            ></app-textarea>
        </app-popup>
    </main>
</template>

<script>
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import { isMobile } from '@/state/state';
import { sortBy, filterMatch, get, uniqBy, getMapById } from '@/services/sanitize.service';
import { differenceInWorkingDays } from '@/services/duration.service';
import AppList from '../../components/appList/AppList';
import locationService from '../../services/location.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import AppButton from '@/components/appButton/AppButton';
import {
    bulkInsert,
    createPreparation,
    getRecommendedEmissionDate,
    removePreparationGroup,
    updatePreparation,
} from '@/features/preparations/preparations.service';
import AppPreparationVisaStatus from '@/components/appPreparationVisaStatus/AppPreparationVisaStatus';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppInputText from '@/components/appInputText/AppInputText';
import AppCancel from '@/components/appCancel/AppCancel';
import IconMenuDown from '@/icons/IconMenuDown';
import IconMenuUp from '@/icons/IconMenuUp';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import { queryProject, updateProject } from '@/features/projects/projects.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getPreparations } from '@/features/preparations/preparations.service';
import { getCompanies } from '@/features/companies/companies.service';
import compareDesc from 'date-fns/compareDesc';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import { importPreparations } from '@/features/preparations/pastePreparations.service';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';

export default {
    components: {
        AppTextarea,
        AppTips,
        AppNumberLink,
        AppNumberInput,
        IconMenuUp,
        IconMenuDown,
        AppCancel,
        AppInputText,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppDateLink,
        AppPreparationVisaStatus,
        AppButton,
        AppQuickActions,
        AppMultiPicker,
        AppSelect,
        AppList,
        AppCheckbox,
    },
    async created() {
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.preparationReviewMargin = project.preparationReviewMargin;
            this.readOnly =
                this.phase === 'exe'
                    ? !project.me.allowedFeatures.includes('project_preparations')
                    : !project.me.allowedFeatures.includes('project_conceptions');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = uniqBy(this.filterFn(this.filterValue), 'groupId').map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'name') {
                        return item.code ? item.code + ' ' + item.name : item.name;
                    } else if (this.sortKey === 'type') {
                        return item.type ? this.$t('preparations.types.' + item.type) : null;
                    } else if (this.sortKey === 'emissionDueDate') {
                        return item.emissionDueDate;
                    } else if (this.sortKey === 'emitter') {
                        return item.bundle ? item.bundle.label : null;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: this.$t('commons.status'),
                    children: [
                        {
                            name: this.$t('preparations.status.toEmit'),
                            id: 'preparations.status.toEmit',
                            firstOfCriteriaType: true,
                            _isStateCriteria: true,
                        },
                        {
                            name: this.$t('preparations.status.toVisa'),
                            id: 'preparations.status.toVisa',
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.emitterCriteriaType'),
                    id: this.$t('commons.emitterCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.label,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.emitterCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.visaEmitterCriteriaType'),
                    id: this.$t('commons.visaEmitterCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        id: 'visaEmitter_' + bundle.id,
                        pickerName: bundle.label,
                        name: `${bundle.label}`,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.visaEmitterCriteriaType'),
                        _isVisaEmitterCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: this.locationOptions
                        .filter((location) => location.type === 'folder' || location.type === 'location')
                        .map((location, index) => ({
                            ...location,
                            name: location.fullName,
                            firstOfCriteriaType: index === 0,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        })),
                },
                {
                    isGroup: true,
                    name: this.$t('preparations.type'),
                    id: this.$t('preparations.type'),
                    children: [
                        { id: 'note', name: this.$t('preparations.types.note'), _isTypeCriteria: true },
                        { id: 'plan', name: this.$t('preparations.types.plan'), _isTypeCriteria: true },
                        {
                            id: 'technicalFile',
                            name: this.$t('preparations.types.technicalFile'),
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'technicalDetail',
                            name: this.$t('preparations.types.technicalDetail'),
                            _isTypeCriteria: true,
                        },
                        { id: 'sample', name: this.$t('preparations.types.sample'), _isTypeCriteria: true },
                        { id: 'prototype', name: this.$t('preparations.types.prototype'), _isTypeCriteria: true },
                        { id: 'synthesis', name: this.$t('preparations.types.synthesis'), _isTypeCriteria: true },
                        ...(this.phase === 'con'
                            ? [
                                  {
                                      id: 'diagnostic',
                                      name: this.$t('preparations.types.diagnostic'),
                                      _isTypeCriteria: true,
                                  },
                                  { id: 'report', name: this.$t('preparations.types.report'), _isTypeCriteria: true },
                              ]
                            : []),
                        { id: 'other', name: this.$t('preparations.types.other'), _isTypeCriteria: true },
                    ],
                },
                ...(this.phase === 'con'
                    ? [
                          {
                              isGroup: true,
                              name: this.$t('commons.phase'),
                              id: this.$t('commons.phase'),
                              children: [
                                  { id: 'DIAG', name: this.$t('preparations.subPhases.DIAG'), _isPhaseCriteria: true },
                                  { id: 'ESQ', name: this.$t('preparations.subPhases.ESQ'), _isPhaseCriteria: true },
                                  { id: 'APS', name: this.$t('preparations.subPhases.APS'), _isPhaseCriteria: true },
                                  { id: 'APD', name: this.$t('preparations.subPhases.APD'), _isPhaseCriteria: true },
                                  { id: 'PC', name: this.$t('preparations.subPhases.PC'), _isPhaseCriteria: true },
                                  { id: 'AVP', name: this.$t('preparations.subPhases.AVP'), _isPhaseCriteria: true },
                                  { id: 'PRO', name: this.$t('preparations.subPhases.PRO'), _isPhaseCriteria: true },
                                  { id: 'DCE', name: this.$t('preparations.subPhases.DCE'), _isPhaseCriteria: true },
                                  {
                                      id: 'OTHER',
                                      name: this.$t('preparations.subPhases.OTHER'),
                                      _isPhaseCriteria: true,
                                  },
                              ],
                          },
                      ]
                    : []),
            ];
        },
        reviewers() {
            const visaEmittersMap = {};
            const emitterListWithDup = this.filteredItems.reduce(
                (acc, preparation) => [
                    ...acc,
                    ...preparation.visas.reduce((acc, visa) => (visa.emitterId ? [...acc, visa.emitterId] : acc), []),
                ],
                [],
            );
            for (const emitterId of emitterListWithDup) {
                if (visaEmittersMap[emitterId]) {
                    visaEmittersMap[emitterId].score = visaEmittersMap[emitterId].score + 1;
                } else {
                    visaEmittersMap[emitterId] = { emitterId, score: 0 };
                }
            }
            return sortBy(Object.values(visaEmittersMap), 'score')
                .reverse()
                .map((entity) => this.bundleMap[entity.emitterId])
                .filter((a) => !!a);
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getPreparations(this.$route.params.projectId, this.phase),
                    getCompanies(this.$route.params.projectId),
                    getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                    getLocationsTree(this.$route.params.projectId),
                ]).subscribe(([bundles, preparations, companies, planning, folders]) => {
                    this.locationOptions = locationService.buildLocationOptions(folders);
                    this.companies = companies.map((company) => ({
                        ...company,
                        bundleIds: bundles
                            .filter((bundle) => bundle.companyId === company.id)
                            .map((bundle) => bundle.id),
                    }));
                    const companyMap = getMapById(companies);
                    this.bundles = sortBy(
                        bundles.map((bundle) => ({ ...bundle, company: companyMap[bundle.companyId] })),
                        (bundle) => bundle.label,
                    );
                    this.bundleMap = getMapById(this.bundles);
                    const locationMap = locationService.getLocationMap(folders);
                    this.restoreSelection();
                    this.items = preparations.map((preparation) => {
                        const bundle = this.bundleMap[preparation.bundleId];
                        return {
                            ...preparation,
                            locations: preparation.locationIds.map((locationId) => locationMap[locationId]),
                            lastVisaByEmitterIdMap: preparation.visas.reduce(
                                (acc, visa) => ({ ...acc, [visa.emitterId]: visa }),
                                {},
                            ),
                            versions: preparation.versions.map((version) => ({
                                ...version,
                                bundle: this.bundleMap[version.bundleId],
                                lastVisaByEmitterIdMap: version.visas.reduce(
                                    (acc, visa) => ({ ...acc, [visa.emitterId]: visa }),
                                    {},
                                ),
                            })),
                            bundle,
                            filterString: [
                                preparation.code,
                                preparation.name,
                                preparation.bundleId && bundle ? bundle.label : '',
                            ].join(),
                            isSelected: this.selection.includes(preparation.id),
                            taskPredecessors: preparation.predecessors
                                .map((predecessor) => planning.find((aTask) => aTask.id === predecessor.taskId))
                                .filter((a) => !!a),
                        };
                    });
                    this.doneCount = this.items.filter((item) => this.isDone(item)).length;
                    this.farCount = this.items.filter((item) => this.isFar(item)).length;
                    this.comingCount = this.items.filter((item) => !this.isDone(item) && !this.isFar(item)).length;
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        scrollToLastVisited() {
            const lastVisitedPreparationId = localStorage.getItem(
                'preparation.lastVisitedPreparationId.' + this.$route.params.projectId,
            );
            if (lastVisitedPreparationId) {
                setTimeout(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedPreparationId);
                    if (element) element.scrollIntoView();
                }, 800);
            }
        },
        async onPaste(e) {
            if (this.$refs.pasteArea.$el.contains(e.target)) {
                const preparations = importPreparations(
                    e,
                    this.$route.params.projectId,
                    this.phase,
                    (key) => this.$t(key),
                    this.bundles,
                );
                this.$refs.importPreparationsPopup.close();
                return bulkInsert(this.$route.params.projectId, preparations);
            }
        },
        get,
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveFilter(this.filterValue);
        },
        saveConfig() {
            return updateProject({
                id: this.$route.params.projectId,
                preparationReviewMargin: this.preparationReviewMargin,
            });
        },
        saveMultiple() {
            this.selection.map((itemId) => {
                const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
                const selectedItem = this.items.find((item) => itemId === item.id);
                const patch = {};
                if (this.editedPreparation.emissionDueDate) {
                    patch.emissionDueDate = this.editedPreparation.emissionDueDate;
                } else if (this.getSelectionCommonEmissionDueDate(selectedItems)) {
                    patch.emissionDueDate = null;
                }
                if (this.editedPreparation.emissionDate) {
                    patch.emissionDate = this.editedPreparation.emissionDate;
                } else if (this.getSelectionCommonEmissionDate(selectedItems)) {
                    patch.emissionDate = null;
                }
                if (this.editedPreparation.type) {
                    patch.type = this.editedPreparation.type;
                } else if (this.getSelectionCommonType(selectedItems)) {
                    patch.type = null;
                }
                if (this.editedPreparation.bundle) {
                    patch.bundleId = this.editedPreparation.bundle.id;
                } else if (this.getSelectionCommonBundle(selectedItems)) {
                    patch.bundleId = null;
                }
                if (this.editedPreparation.applyRecommended && !selectedItem.emissionDate) {
                    const firstTask = selectedItem?.taskPredecessors
                        ? [...selectedItem.taskPredecessors]
                              .sort((taskA, taskB) =>
                                  compareDesc(
                                      taskA.realStartDate || taskA.startDate,
                                      taskB.realStartDate || taskB.startDate,
                                  ),
                              )
                              .pop()
                        : null;
                    const visaDuration = selectedItem.visas.reduce(
                        (acc, visa) => (visa.emitter && visa.emitter.visaDelay > acc ? visa.emitter.visaDelay : acc),
                        0,
                    );
                    const recommendedDate = getRecommendedEmissionDate(
                        this.preparationReviewMargin,
                        selectedItem.deliveryDuration,
                        firstTask?.realStartDate || firstTask?.startDate,
                        visaDuration,
                        this.agenda,
                    );
                    if (recommendedDate && recommendedDate > new Date()) {
                        patch.emissionDueDate = recommendedDate;
                    }
                }
                return updatePreparation(this.$route.params.projectId, { id: itemId, ...patch });
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removePreparationGroup(this.$route.params.projectId, item.groupId)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedPreparation = {
                    emissionDueDate: this.getSelectionCommonEmissionDueDate(selectedItems),
                    emissionDate: this.getSelectionCommonEmissionDate(selectedItems),
                    type: this.getSelectionCommonType(selectedItems),
                    bundle: this.getSelectionCommonBundle(selectedItems),
                    locations: [],
                    applyRecommended: false,
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        getSelectionCommonType(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.type);
            if (firstItem && selectedItems.every((item) => item.type === firstItem.type)) {
                return firstItem.type;
            } else {
                return '';
            }
        },
        getSelectionCommonBundle(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.bundle);
            if (firstItem && selectedItems.every((item) => item.bundle && item.bundle.id === firstItem.bundle.id)) {
                return firstItem.bundle;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.emissionDate && item.emissionDate.getTime() === firstItem.emissionDate.getTime(),
                )
            ) {
                return firstItem.emissionDate;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDueDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) =>
                        item.emissionDueDate && item.emissionDueDate.getTime() === firstItem.emissionDueDate.getTime(),
                )
            ) {
                return firstItem.emissionDueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'preparations.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('preparations.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addPreparation() {
            const result = await createPreparation(this.$route.params.projectId, {
                name: this.$t('preparations.newName'),
                deliveryDuration: 5,
                phase: this.phase,
            });
            await this.$router.push({
                name: this.phase === 'exe' ? 'followPreparation' : 'conception',
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
        isDone(item) {
            return (
                item.emissionDate &&
                item.visas.every(
                    (visa) =>
                        visa.emissionDate &&
                        (visa.conclusion === 'approved' || visa.conclusion === 'approvedWithComments'),
                )
            );
        },
        isFar(item) {
            const diff = differenceInWorkingDays(
                item.emissionDueDate,
                new Date(),
                this.agenda,
                item.bundle ? item.bundle.id : null,
            );
            if (this.delay === 'all') {
                return !item.emissionDate && diff > 1;
            } else {
                return !item.emissionDate && diff > 1 && diff < parseInt(this.delay);
            }
        },
        matchBundleFilter(item, bundleIds) {
            return (
                (item.bundle && bundleIds.includes(item.bundle.id)) ||
                item.visas.find((visa) => visa.emitter && bundleIds.includes(visa.emitter.id))
            );
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString || item.name, criteria, true));
        },
        matchType(typeCriteria, item) {
            if (!typeCriteria || typeCriteria.length === 0) {
                return true;
            }
            return typeCriteria.find((criteria) => item.type === criteria.id);
        },
        matchStatusFilter(item) {
            const isFar = this.isFar(item);
            const isDone = this.isDone(item);
            return (!isDone && !isFar && this.coming) || (isFar && this.far) || (isDone && this.done);
        },
        matchSubPhaseCriteria(criterias, item) {
            if (!criterias || criterias.length === 0) {
                return true;
            }
            return criterias.find((criteria) => item.subPhase === criteria.id);
        },
        matchVisaEmitterFilter(item, bundleIds) {
            return item.visas.find(({ emitterId }) => bundleIds.includes(emitterId));
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'preparations_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    done: this.done,
                    coming: this.coming,
                    far: this.far,
                    onlyLastVersion: this.onlyLastVersion,
                    sortKey: this.sortKey,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('preparations_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.done = !!parsedCache.done;
                this.coming = !!parsedCache.coming;
                this.far = !!parsedCache.far;
                this.onlyLastVersion = !!parsedCache.onlyLastVersion;
                this.sortKey = parsedCache.sortKey || 'name';
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = [
                ...filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id),
                ...filter
                    .filter((aCriteria) => aCriteria._isCompanyCriteria)
                    .reduce((acc, company) => [...acc, ...(company.bundleIds || [])], []),
            ];
            const visaEmitterIds = filter
                .filter((aCriteria) => aCriteria._isVisaEmitterCriteria)
                .map((aCriteria) => aCriteria.id.substring('visaEmitter_'.length));

            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria);
            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);
            const toEmitCriteria = filter.filter((aCriteria) => aCriteria.id === 'preparations.status.toEmit');
            const toVisaCriteria = filter.filter((aCriteria) => aCriteria.id === 'preparations.status.toVisa');
            const phaseCriteria = filter.filter((aCriteria) => aCriteria._isPhaseCriteria);

            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchVisaEmitterFilter:
                        visaEmitterIds.length === 0 || this.matchVisaEmitterFilter(item, visaEmitterIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchLocationCriteria: locationService.matchLocationCriteria(locationCriteria, item.locations),
                    matchPhaseCriteria: this.matchSubPhaseCriteria(phaseCriteria, item),
                    matchString: this.matchString(stringCriteria, item),
                    matchType: this.matchType(typeCriteria, item),
                    matchToEmit: toEmitCriteria.length === 0 || !item.emissionDate,
                    matchToVisa: toVisaCriteria.length === 0 || (!!item.emissionDate && !this.isDone(item)),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        updateDueDate(preparation, date) {
            updatePreparation(this.$route.params.projectId, { emissionDueDate: date, id: preparation.id });
        },
        updateDate(preparation, date) {
            if (preparation.emissionDueDate) {
                updatePreparation(this.$route.params.projectId, {
                    emissionDate: date,
                    id: preparation.id,
                });
            } else {
                updatePreparation(this.$route.params.projectId, {
                    id: preparation.id,
                    emissionDueDate: date,
                    emissionDate: date,
                });
            }
        },
        focusToNextDate(preparation) {
            let index = this.filteredItems.findIndex((aPreparation) => aPreparation.id === preparation.id);
            let nextPreparation = this.filteredItems[index + 1];
            while (!nextPreparation && index < this.filteredItems.length) {
                nextPreparation = this.filteredItems[++index];
            }
            if (nextPreparation) {
                this.$emit('select', nextPreparation);
                const element = this.$refs['date_' + nextPreparation.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextDueDate(preparation) {
            let index = this.filteredItems.findIndex((aPreparation) => aPreparation.id === preparation.id);
            let nextPreparation = this.filteredItems[index + 1];
            while (!nextPreparation && index < this.filteredItems.length) {
                nextPreparation = this.filteredItems[++index];
            }
            if (nextPreparation) {
                this.$emit('select', nextPreparation);
                const element = this.$refs['dueDate_' + nextPreparation.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            bundleMap: {},
            preparationReviewMargin: 0,
            loading: 0,
            doneCount: 0,
            farCount: 0,
            comingCount: 0,
            quickActions: [
                {
                    name: this.$t('preparations.newPreparation'),
                    run: () => this.addPreparation(),
                },
                {
                    name: this.$t('preparations.importPreparations'),
                    run: () => this.$refs.importPreparationsPopup.open(),
                },
            ],
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            editedPreparation: {
                emissionDueDate: null,
                emissionDate: null,
                type: null,
                bundle: null,
                locations: [],
                applyRecommended: false,
            },
            subscriptions: [],
            agenda: [],
            bundles: [],
            companies: [],
            isMobile,
            items: [],
            done: false,
            coming: true,
            far: false,
            onlyLastVersion: true,
            filterValue: [],
            locationOptions: [],
            delay: 'all',
            phase: this.$route.name === 'conceptions' ? 'con' : 'exe',
        };
    },
};
</script>
