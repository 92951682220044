<template>
    <div class="m-auto fixed w-full h-full inset-0 items-center justify-center flex-col flex">
        <main class="w-full sm:max-w-md grow md:grow-0 md:shadow-2xl p-3">
            <div class="m-5 mb-5">
                <div class="p-3 text-main font-bold text-center">{{ $t('cgus.title') }}</div>
                <div>
                    <span>{{ $t('cgus.contentPrefix') }}</span>
                    <a class="underline" target="_blank" href="https://www.tex.moe/cgu-cgv-mentions-légales">
                        <span>{{ $t('cgus.contentLink') }}</span>
                    </a>
                    <a target="_blank" href="https://www.tex.moe/cgu-cgv-mentions-légales">
                        <icon-open-in-new
                            class="inline mx-1"
                            width="16"
                            height="16"
                            :alt="$t('cgus.contentLink')"
                        ></icon-open-in-new>
                    </a>
                </div>
                <div>{{ $t('cgus.thanks') }}</div>
                <div class="text-right">{{ $t('cgus.emitter') }}</div>
            </div>
            <ValidationObserver>
                <form class="m-5 p-3 flex flex-col gap-3">
                    <label>
                        <input type="checkbox" required class="mx-1" v-model="checked" />
                        {{ $t('cgus.approveCGU') }}
                    </label>
                    <app-footer @click="approveCGU()" :disabled="!checked" :ok-label="$t('cgus.validate')"></app-footer>
                </form>
            </ValidationObserver>
        </main>
    </div>
</template>

<script>
import AppFooter from '../components/appFooter/AppFooter';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import { approveCGU } from '@/features/cgus/cgus.service';

export default {
    components: { AppCheckbox, AppFooter },
    data() {
        return {
            checked: false,
        };
    },
    methods: {
        approveCGU: async function () {
            await approveCGU();
            this.$router.push({ name: 'home' });
        },
    },
};
</script>
