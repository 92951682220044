<template>
    <div class="m-auto fixed w-full h-full inset-0 md:items-center flex-col flex overflow-auto">
        <div class="flex items-center m-4 gap-4">
            <img src="/logoTex.svg" alt="Tex logo" class="w-16 mb-1" style="width: 64px" />
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer" tag="form">
            <div class="md:m-5 flex flex-col w-full border shadow md:shadow-2xl">
                <h1 class="bg-main font-bold text-white">
                    <div class="p-3">{{ $t('order.project.new.title') }}</div>
                </h1>
                <div class="p-4 relative">
                    <div v-if="step === ACCOUNT_STEP && !alreadyUser" class="grid grid-cols-2 gap-4 p-4">
                        <h2 class="text-lg font-bold mb-2 col-span-2">
                            Etape 1/4 : {{ $t('newProjectShort.createAccount') }}
                        </h2>
                        <app-legend class="absolute top-0 right-0 m-2"></app-legend>
                        <app-input-text
                            :label="$t('users.firstName')"
                            :required="true"
                            v-model="user.firstName"
                            class="col-span-2 md:col-span-1"
                        />
                        <app-input-text
                            :label="$t('users.lastName')"
                            :required="true"
                            v-model="user.lastName"
                            class="col-span-2 md:col-span-1"
                        />
                        <app-input-text
                            :label="$t('users.email')"
                            :required="true"
                            type="email"
                            v-model="user.email"
                            class="col-span-2"
                        >
                            <template v-slot:tip>
                                <app-tips>{{ $t('users.emailTip') }}</app-tips>
                            </template>
                        </app-input-text>

                        <app-phone-input
                            :label="$t('users.phone')"
                            :required="true"
                            v-model="user.phone"
                            class="col-span-2"
                        ></app-phone-input>

                        <app-input-text
                            :label="$t('users.company')"
                            :required="true"
                            v-model="user.companyName"
                            class="col-span-2"
                        ></app-input-text>

                        <label class="col-span-2 text-sm my-5">
                            <input type="checkbox" required class="mx-1" v-model="cguChecked" />
                            {{ $t('cgus.approveCGUPrefix') }}
                            <a href="https://www.tex.moe/cgu-cgv-mentions-légales" target="_blank" class="underline">
                                {{ $t('cgus.approveCGULink') }}
                            </a>
                            {{ $t('cgus.approveCGUSuffix') }}
                            <span class="text-red-700 ml-1" :title="$t('commons.required')">*</span>
                        </label>
                    </div>
                    <div v-if="step === ACCOUNT_STEP && alreadyUser" class="flex flex-col gap-4">
                        <h2 class="text-lg font-bold mb-2">Etape 1/4 : {{ $t('login.title') }}</h2>
                        <app-legend class="absolute top-0 right-0 m-2"></app-legend>
                        <div class="">
                            <app-input-text
                                rules="required"
                                :required="true"
                                type="email"
                                v-model="user.email"
                                :label="$t('users.email')"
                            ></app-input-text>
                        </div>
                        <div class="">
                            <app-input-text
                                rules="required"
                                :required="true"
                                type="password"
                                v-model="user.password"
                                :label="$t('login.password')"
                            ></app-input-text>
                        </div>
                    </div>
                    <div class="flex flex-col gap-4 w-full" v-if="step === PROJECT_NAME_STEP">
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-if="in4Step">
                            Etape 2/4 : {{ $t('newProjectShort.projectInfo') }}
                        </h2>
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-else>
                            Etape 1/3 : {{ $t('newProjectShort.projectInfo') }}
                        </h2>
                        <app-legend class="absolute top-0 right-0 m-2"></app-legend>
                        <app-input-text
                            :label="$t('commons.name')"
                            :required="true"
                            v-model="project.name"
                            class="col-span-2"
                        >
                            <template v-slot:tip>
                                <app-tips>{{ $t('order.project.new.nameTip') }}</app-tips>
                            </template>
                        </app-input-text>
                        <app-input-text :label="$t('commons.code')" v-model="project.code" class="col-span-2">
                            <template v-slot:tip>
                                <app-tips>{{ $t('order.project.new.codeTip') }}</app-tips>
                            </template>
                        </app-input-text>
                        <span class="col-span-2">
                            <AppSelect
                                id="type"
                                name="type"
                                v-model="project.type"
                                :label="$t('project.information.generals.type')"
                                :required="true"
                            >
                                <option>Résidentiel collectif</option>
                                <option>Résidentiel individuel</option>
                                <option>Educatif</option>
                                <option>Tertiaire</option>
                                <option>Commercial</option>
                                <option>Industriel</option>
                                <option>Agricole</option>
                                <option>Santé</option>
                                <option>Récréatif et culturel</option>
                                <option>Religieux</option>
                                <option>Transport</option>
                                <option>Voirie / Espaces Verts</option>
                                <option>Sportif</option>
                                <option>Autre</option>
                            </AppSelect>
                        </span>
                        <span class="col-span-2">
                            <AppSelect
                                id="typeOfWork"
                                name="typeOfWork"
                                v-model="project.typeOfWork"
                                :label="$t('project.information.generals.typeOfWork')"
                                :required="true"
                            >
                                <option>Neuf</option>
                                <option>Rénovation extérieure</option>
                                <option>Rénovation énergétique</option>
                                <option>Rénovation intérieure</option>
                                <option>Rénovation structurelle</option>
                                <option>Réhabilitation</option>
                            </AppSelect>
                        </span>
                        <app-number-input
                            class="col-span-2 my-4"
                            v-model="project.size"
                            :label="$t('order.project.dimension') + ' (m²)'"
                            :required="true"
                            format="integer"
                        >
                            <template v-slot:tip>
                                <app-tips class="flex flex-col">
                                    <span>{{ $t('order.project.dimensionTips1') }}</span>
                                    <span>{{ $t('order.project.dimensionTips2') }}</span>
                                    <span>{{ $t('order.project.dimensionTips3') }}</span>
                                </app-tips>
                                <div class="flex flex-col md:flex-row items-center p-5 justify-between">
                                    <img
                                        src="/dimension1.png"
                                        width="150"
                                        alt="Exemple de calcul de dimension pour un batiment simple"
                                    />
                                    <img
                                        src="/dimension2.png"
                                        width="200"
                                        alt="Exemple de calcul de dimension pour un batiment avec un décroché"
                                    />
                                    <img
                                        src="/dimension3.png"
                                        width="300"
                                        alt="Exemple de calcul de dimension pour un batiment avec toiture et terrasse"
                                    />
                                </div>
                            </template>
                        </app-number-input>
                    </div>
                    <div class="flex flex-col gap-4 w-full items-start" v-if="step === LICENSE_BUNDLE_TYPE_STEP">
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-if="in4Step">
                            Etape 3/4 : {{ $t('order.project.new.bundleType') }}
                        </h2>
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-else>
                            Etape 2/3 : {{ $t('order.project.new.bundleType') }}
                        </h2>
                        <app-label>
                            <input type="radio" value="MOA" name="bundleType" v-model="bundleType" />
                            {{ $t('order.project.new.bundleTypes.MOA') }}
                        </app-label>
                        <app-label>
                            <input type="radio" value="AMOA" name="bundleType" v-model="bundleType" />
                            {{ $t('order.project.new.bundleTypes.AMOA') }}
                        </app-label>
                        <app-label>
                            <input type="radio" value="MOE" name="bundleType" v-model="bundleType" />
                            {{ $t('order.project.new.bundleTypes.MOE') }}
                        </app-label>
                        <app-label>
                            <input type="radio" value="generalContractor" name="bundleType" v-model="bundleType" />
                            {{ $t('order.project.new.bundleTypes.generalContractor') }}
                        </app-label>
                    </div>
                    <div class="flex flex-col gap-4 w-full items-start" v-if="step === LICENSE_PERIMETER_STEP">
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-if="in4Step">
                            Etape 4/4 : {{ $t('order.project.new.perimeter') }}
                        </h2>
                        <h2 class="text-lg font-bold mb-2 col-span-2" v-else>
                            Etape 3/3 : {{ $t('order.project.new.perimeter') }}
                        </h2>
                        <app-checkbox :label="$t('order.project.new.perimeterValues.CON')" v-model="CON" />
                        <app-checkbox :label="$t('order.project.new.perimeterValues.EXE')" v-model="EXE" />
                        <app-checkbox :label="$t('order.project.new.perimeterValues.OPR')" v-model="OPR" />
                        <app-checkbox :label="$t('order.project.new.perimeterValues.OPL')" v-model="OPL" />
                        <app-checkbox
                            :label="$t('order.project.new.perimeterValues.company')"
                            class="mt-4"
                            v-model="company"
                        />
                    </div>
                    <div class="mt-4 flex justify-between gap-2">
                        <button
                            class="underline"
                            type="button"
                            @click.prevent="toggleAlreadyUser"
                            v-if="step === ACCOUNT_STEP"
                        >
                            <span v-if="alreadyUser">{{ $t('newProjectShort.createAccount') }}</span>
                            <span v-else>{{ $t('newProjectShort.alreadyHaveAccount') }}</span>
                        </button>
                        <app-button
                            v-if="step > 1"
                            :label="$t('order.project.new.previous')"
                            @click.prevent="previous"
                            :disabled="loading"
                        ></app-button>
                        <span v-else></span>
                        <div class="flex justify-end items-center gap-2">
                            <app-button
                                :label="
                                    step === LICENSE_PERIMETER_STEP
                                        ? $t('order.project.new.createProject')
                                        : $t('order.project.new.next')
                                "
                                @click.prevent="next"
                                variant="primary"
                                :disabled="
                                    loading ||
                                    invalid ||
                                    invalidPerimeter ||
                                    (step === ACCOUNT_STEP && !alreadyUser && !cguChecked)
                                "
                                type="submit"
                            ></app-button>
                            <icon-rotate-right class="animate animate-spin" v-show="loading"></icon-rotate-right>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import { getLoggedUser, getToken } from '@/features/tokens/token.service';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppLegend from '@/components/appLegend/AppLegend.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';
import IconRotateRight from '@/icons/IconRotateRight.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import IconFilePdfOutline from '@/icons/IconFilePdfOutline.vue';
import { createUser } from '@/features/users/users.service';
import { error } from '@/features/toasts/toats.service';
import { approveCGU } from '@/features/cgus/cgus.service';
import database, { reconnect, replicateProjects } from '@/rxdb/database';
import { createProject } from '@/features/projects/projects.service';
import AppPhoneInput from '@/components/appPhoneInput/AppPhoneInput.vue';

export default {
    components: {
        AppPhoneInput,
        IconFilePdfOutline,
        AppFileLink,
        IconRotateRight,
        AppTextarea,
        AppNumberInput,
        AppCheckbox,
        AppLabel,
        AppSelect,
        AppTips,
        AppLegend,
        AppButton,
        AppFooter,
        AppInputText,
        AppSeparator,
    },
    created() {
        this.myUser = getLoggedUser();
        if (this.myUser) {
            this.step = 1;
            this.in4Step = false;
        } else {
            this.in4Step = true;
        }
    },
    methods: {
        toggleAlreadyUser() {
            this.alreadyUser = !this.alreadyUser;
        },
        toProject() {
            this.$router.push({ name: 'project', params: { projectId: this.createdProject.id } });
        },
        async login() {
            let tokenResponse = null;
            try {
                tokenResponse = await getToken(this.user.email, this.user.password);
                localStorage.setItem('token', tokenResponse.token);
                localStorage.setItem('userId', getLoggedUser().id);
                reconnect();
            } catch (e) {
                // nop
            }
            if (!tokenResponse) {
                error(this.$t('login.error'));
            }
        },
        async next() {
            if (this.step === this.ACCOUNT_STEP && this.alreadyUser) {
                this.loading = true;
                try {
                    await this.login();
                    this.myUser = getLoggedUser();
                    this.step++;
                } catch (e) {
                    console.error(e);
                }
                this.loading = false;
            } else if (this.step === this.ACCOUNT_STEP && !this.alreadyUser) {
                this.loading = true;
                try {
                    await createUser(this.user);
                    await approveCGU();
                    try {
                        this.myUser = getLoggedUser();
                        this.$refs.observer.reset();
                        this.step++;
                    } catch (e) {
                        this.alreadyUser = true;
                    }
                } catch (e) {
                    console.error(e);
                }
                this.loading = false;
            } else if (this.step === this.LICENSE_PERIMETER_STEP) {
                this.loading = true;
                await this.createProject();
                await replicateProjects();
                this.toProject();
            } else {
                this.step++;
            }
        },
        previous() {
            this.step--;
        },
        async createProject() {
            const modules = [];
            if (this.CON) {
                modules.push('CON');
            }
            if (this.EXE) {
                modules.push('EXE');
            }
            if (this.OPR) {
                modules.push('OPR');
            }
            if (this.OPL) {
                modules.push('OPL');
            }
            if (this.company) {
                modules.push('company');
            }
            const project = await createProject(this.project, { type: this.bundleType }, modules);
            await database.replicateProject(project.id);
            this.createdProject = project;
        },
    },
    computed: {
        invalidPerimeter() {
            return this.step === this.LICENSE_PERIMETER_STEP && !(this.CON || this.EXE || this.OPR || this.OPL);
        },
    },
    data() {
        return {
            cguChecked: false,
            in4Step: true,
            ACCOUNT_STEP: 0,
            PROJECT_NAME_STEP: 1,
            LICENSE_BUNDLE_TYPE_STEP: 2,
            LICENSE_PERIMETER_STEP: 3,
            CON: false,
            EXE: false,
            OPR: false,
            OPL: false,
            company: false,
            loading: false,
            step: 0,
            bundleType: 'MOA',
            project: {
                size: 0,
                type: 'Habitat',
                code: new Date().getFullYear() + '-' + (Math.random() + 1).toString(36).substring(2, 5).toUpperCase(),
                name: '',
            },
            myUser: null,
            alreadyUser: false,
            createdProject: { newProjectId: '' },
            user: {
                firstName: '',
                lastName: '',
                email: '',
            },
        };
    },
};
</script>
