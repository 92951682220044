<template>
    <div class="m-auto fixed w-full h-full inset-0 items-center justify-center flex-col flex">
        <div class="flex flex-col items-center mb-5" style="width: 12rem">
            <img src="/logoTex.svg" alt="Tex logo" class="mb-1" />
        </div>
        <main class="w-full sm:max-w-sm md:shadow-2xl">
            <h1 class="hidden md:block bg-main font-bold text-white mb-2" :class="{ 'p-1': !header }">
                <div class="p-3" v-if="header">{{ header }}</div>
            </h1>
            <ValidationObserver ref="observer">
                <form class="m-5 flex flex-col gap-4">
                    <div>
                        <app-input-text
                            rules="required"
                            type="email"
                            v-model="email"
                            :label="$t('users.email')"
                        ></app-input-text>
                    </div>
                    <div>
                        <app-input-text
                            rules="required"
                            :type="passwordVisible ? 'text' : 'password'"
                            v-model="password"
                            :label="$t('login.password')"
                        >
                            <template v-slot:link>
                                <app-button
                                    variant="borderless"
                                    :icon="passwordVisible ? 'icon-eye-off-outline' : 'icon-eye-outline'"
                                    @click="passwordVisible = !passwordVisible"
                                ></app-button>
                            </template>
                        </app-input-text>
                    </div>
                    <app-footer @click="login()" :ok-label="$t('login.title')"></app-footer>
                    <div class="flex justify-between">
                        <router-link :to="{ name: 'newUser', query: { email } }" class="underline text-sm">
                            {{ $t('user.newUser') }}
                        </router-link>
                        <router-link :to="{ name: 'resetPassword', query: { email } }" class="underline text-sm">
                            {{ $t('user.resetPassword') }}
                        </router-link>
                    </div>
                </form>
            </ValidationObserver>
        </main>
    </div>
</template>

<script>
import { error } from '@/features/toasts/toats.service';
import { getLoggedUser, getToken } from '@/features/tokens/token.service';
import AppInputText from '../components/appInputText/AppInputText';
import AppFooter from '../components/appFooter/AppFooter';
import { initGlobalDatabase } from '@/rxdb/database';
import AppButton from '@/components/appButton/AppButton.vue';

export default {
    components: { AppButton, AppInputText, AppFooter },
    props: { header: { type: String, default: '' } },
    data: () => ({
        email: '',
        password: '',
        passwordVisible: false,
    }),
    created() {
        this.email = this.$route.query.email || '';
    },
    methods: {
        login: async function () {
            const validationResult = await this.$refs.observer.validate();
            if (validationResult) {
                try {
                    const result = await getToken(this.email, this.password);
                    if (result) {
                        localStorage.setItem('token', result.token);
                        localStorage.setItem('userId', getLoggedUser().id);
                        await initGlobalDatabase();
                        if (result.cguApprovalRequired) {
                            this.$router.push({ name: 'cgu' });
                        } else if (this.$route.query.redirectTo) {
                            if (typeof this.$route.query.redirectTo === 'string') {
                                window.location = this.$route.query.redirectTo;
                            } else {
                                window.location = this.$route.query.redirectTo.href;
                            }
                        } else {
                            this.$router.push({ name: 'home' });
                        }
                    } else {
                        error(this.$t('login.error'));
                    }
                } catch (e) {
                    console.error(e);
                    error(this.$t('commons.connectionError'));
                }
            }
        },
    },
};
</script>
