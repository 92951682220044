<template>
    <main class="flex flex-col justify-center w-full sm:max-w-sm m-auto min-h-screen">
        <div class="md:shadow-2xl">
            <h1 class="flex bg-main font-bold text-white mb-2 p-2">{{ $t('user.newUserTitle') }}</h1>
            <ValidationObserver v-slot="{ invalid }">
                <form class="max-w-md grid grid-cols-2 gap-4 p-4">
                    <app-legend class="col-span-2"></app-legend>
                    <app-input-text
                        :label="$t('users.firstName')"
                        :required="true"
                        v-model="user.firstName"
                        class="col-span-2 md:col-span-1"
                    />

                    <app-input-text
                        :label="$t('users.lastName')"
                        :required="true"
                        v-model="user.lastName"
                        class="col-span-2 md:col-span-1"
                    />

                    <app-input-text
                        :label="$t('users.email')"
                        :required="true"
                        type="email"
                        v-model="user.email"
                        class="col-span-2"
                    >
                        <template v-slot:tip>
                            <app-tips>{{ $t('users.emailTip') }}</app-tips>
                        </template>
                    </app-input-text>

                    <app-phone-input
                        :label="$t('users.phone')"
                        :required="true"
                        v-model="user.phone"
                        class="col-span-2"
                    ></app-phone-input>

                    <app-input-text
                        :label="$t('users.company')"
                        :required="true"
                        v-model="user.companyName"
                        class="col-span-2"
                    ></app-input-text>

                    <app-footer @click.prevent="createUser()" :disabled="invalid" :ok-label="$t('user.newUser')" />
                </form>
            </ValidationObserver>
            <div class="mx-5 mb-5">
                <router-link :to="{ name: 'login', query: { email: this.user.email } }" class="underline text-sm">
                    {{ $t('login.title') }}
                </router-link>
            </div>
        </div>
    </main>
</template>

<script>
import AppErrors from '../../components/appErrors/AppErrors';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFooter from '../../components/appFooter/AppFooter';
import { createUser } from './users.service';
import AppLegend from '@/components/appLegend/AppLegend';
import AppTips from '@/components/app-tips/AppTips';
import AppPhoneInput from '@/components/appPhoneInput/AppPhoneInput.vue';
export default {
    components: { AppPhoneInput, AppTips, AppLegend, AppFooter, AppInputText, AppErrors },
    created() {
        this.user.email = this.$route.query.email || '';
    },
    methods: {
        createUser: async function () {
            try {
                await createUser(this.user);
                this.$router.push({ name: 'confirmedUser', query: { email: this.user.email } });
            } catch (err) {
                console.error(err);
            }
        },
    },
    data: () => {
        return {
            user: {
                firstName: '',
                lastName: '',
                email: '',
            },
        };
    },
};
</script>
