import { getAPIHeaders } from '@/services/api.service';
import { getGroupDuration } from '@/services/duration.service';

async function getTasks(projectId, planningId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders(),
    });
    return (await result.json()).map(mapTask);
}

function mapTask(item) {
    return item
        ? {
              ...item,
              plannedStartDate: item.plannedStartDate ? new Date(item.plannedStartDate) : null,
              plannedEndDate: item.plannedEndDate ? new Date(item.plannedEndDate) : null,
              realStartDate: item.realStartDate ? new Date(item.realStartDate) : null,
              realEndDate: item.realEndDate ? new Date(item.realEndDate) : null,
          }
        : null;
}

async function createTask(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function deleteTasks(projectId, planningId, taskId) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${taskId}`, {
        headers: getAPIHeaders(),
        method: 'DELETE',
    });
    return result.json();
}

async function updateTask(projectId, planningId, task) {
    const result = await fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks/${task.id}`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'PUT',
        body: JSON.stringify(task),
    });
    return result.json();
}

async function getPlannings(projectId) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders(),
    });
    return result.json();
}

async function createPlanning(projectId, planning) {
    const result = await fetch(`/api/projects/${projectId}/plannings`, {
        headers: getAPIHeaders({ 'content-type': `application/json` }),
        method: 'POST',
        body: JSON.stringify(planning),
    });
    return result.json();
}
function importCSV(projectId, planningId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        method: 'PUT',
        body: formData,
        headers: getAPIHeaders(),
    });
}
function importXML(projectId, planningId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`/api/projects/${projectId}/plannings/${planningId}/tasks`, {
        method: 'PUT',
        body: formData,
        headers: getAPIHeaders(),
    });
}

export function treeToLines(roots) {
    const result = [];
    for (const node of roots) {
        treeToLines_recursive(node, result);
    }
    return result;
}
export function treeToLines_recursive(node, result) {
    result.push(node);
    if (node.children) {
        for (const child of node.children) {
            treeToLines_recursive(child, result);
        }
    }
}
export function applyGroupDates(roots) {
    for (const node of roots) {
        applyGroupDates_recursive(node);
    }
    return roots;
}
export function applyGroupDates_recursive(node) {
    if (node.children?.length) {
        for (const child of node.children) {
            applyGroupDates_recursive(child);
        }
        const dates = node.children.reduce((acc, child) => {
            if (child.plannedStartDate && (!acc.plannedStartDate || child.plannedStartDate < acc.plannedStartDate)) {
                acc.plannedStartDate = child.plannedStartDate;
            }
            if (child.plannedEndDate && (!acc.plannedEndDate || child.plannedEndDate > acc.plannedEndDate)) {
                acc.plannedEndDate = child.plannedEndDate;
            }
            if (child.realStartDate && (!acc.realStartDate || child.realStartDate < acc.realStartDate)) {
                acc.realStartDate = child.realStartDate;
            }
            if (child.realEndDate && (!acc.realEndDate || child.realEndDate > acc.realEndDate)) {
                acc.realEndDate = child.realEndDate;
            }
            return acc;
        }, {});
        node.plannedStartDate = dates.plannedStartDate;
        node.plannedEndDate = dates.plannedEndDate;
        node.plannedDuration = getGroupDuration(node.plannedStartDate, node.plannedEndDate);
        node.realStartDate = dates.realStartDate;
        node.realEndDate = dates.realEndDate;
        if (node.realStartDate && node.realEndDate) {
            node.realDuration = getGroupDuration(node.realStartDate, node.realEndDate);
        }
    } else {
        return node;
    }
}
export default {
    getTasks,
    createTask,
    updateTask,
    getPlannings,
    createPlanning,
    deleteTasks,
    importCSV,
    importXML,
    applyGroupDates,
};
